import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { CloseIcon } from '@snapi/icons';
import { ReactComponent as EmptyBacteria } from './images/bacteria-empty.svg';
import { ReactComponent as FullBacteria } from './images/bacteria-full.svg';
import { SubscriptionForm } from '../layout/footer/footer-subscription-form';
import SubscriptionNotification from '../landing/learn/components/subscription-notification';
import { Input } from '@snapi/form';

const PopUpKey = 'snapi:landing-pop-up-triggered';

const Overlay = {
  visible: { opacity: 0.75 },
  hidden: { opacity: 0 },
};

export function PopUp() {
  const [popupOpen, togglePopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const onClickOverlay = (e) => {
    if (e.target === e.currentTarget) {
      togglePopup(false);
    }
  };

  useEffect(() => {
    const popupTriggered = localStorage?.getItem(PopUpKey) ?? false;
    if (!popupTriggered) {
      setTimeout(() => {
        localStorage?.setItem(PopUpKey, 'true');
        togglePopup(true);
      }, 5000);
    }
  }, []);

  const {
    register,
    formState: { errors, isSubmitting },
    reset,
    setError,
    handleSubmit,
  } = useForm<SubscriptionForm>({});

  const onSubmit = async (form: SubscriptionForm, e) => {
    const req = {
      ...form,
      audienceId: '6e3bdda2a9',
      tags: ['Landing Discount Popup'],
    };
    const res = await fetch('https://api.snapihealth.com/mail/subscription', {
      method: 'POST',
      body: JSON.stringify(req),
    });

       const responseKlavio = await fetch(
      'https://www.snapihealth.com/api/subscription',
      {
        method: 'post',
        body: JSON.stringify({ ...form, listId: 'Y96sNd' }),
      }
    );
    console.log(responseKlavio.status);

    if (res.status === 200) {
      onClickCloseVectorHandler(e);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    if (res.status >= 500) {
      onClickCloseVectorHandler(e);
      setShowNotification(true);
      setShowErrorNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }

    if (!res.ok) {
      if (res.status === 400) {
        setError('email', { message: 'Looks invalid, please check.' });
        Sentry.captureException(errors);
      }
    } else {
      reset();
    }
  };

  const onClickCloseVectorHandler = (e) => {
    if (e.target) {
      togglePopup(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        {popupOpen && (
          <motion.div
            variants={Overlay}
            transition={{ duration: 0.55 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={onClickOverlay}
            className="bg-body bg-opacity-90 fixed top-0 bottom-0 left-0 right-0  flex justify-center w-full z-[60]"
          />
        )}
      </AnimatePresence>
      <div
        className={
          popupOpen
            ? 'fixed z-[70] inset-y-0 my-auto mx-auto inset-x-0 flex flex-col justify-center items-center bg-blue h-[500px] w-[290px] py-20 px-8 rounded-2xl overflow-hidden md:w-[560px] md:h-[440px]'
            : 'hidden'
        }
      >
        <div className="absolute top-[-80px] left-[-80px] md:top-[-40px] md:left-[-40px]">
          <EmptyBacteria />
        </div>
        <div className="absolute top-[-70px] right-[20px] rotate-[55deg] md:right-[30px] md:top-[-60px]">
          <FullBacteria />
        </div>
        <div
          className="absolute top-5 right-5"
          onClick={onClickCloseVectorHandler}
        >
          <CloseIcon
            width={10}
            height={16}
            className="cursor-pointer text-blue-dark"
          />
        </div>
        <h2 className="text-blue-dark text-center w-[222px] md:w-[406px] mb-10">
          Subscribe for <span className="text-white">Snapi updates</span> and
          get 10% off for your first order
        </h2>
        <form
          className="flex flex-col justify-center items-center gap-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="email"
            placeholder="email"
            className="w-[260px] md:w-[280px]"
            {...register('email', { required: true })}
          />
          <button
            disabled={isSubmitting}
            type="submit"
            className="w-[260px] h-[60px] md:w-[280px] rounded-[10px] uppercase disabled:bg-gray-soft disabled:shadow-none disabled:border-none text-body bg-green shadow-[1px_2px_0_0_#7DA46C] hover:bg-green-hover hover:shadow-none focus:bg-green-focus focus:shadow-none"
          >
            {isSubmitting ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <h5 className="uppercase">Subscribe</h5>
            )}
          </button>
        </form>
        <div className="absolute bottom-[20px] left-[-110px] md:bottom-[50px] md:left-[-20px]">
          <FullBacteria />
        </div>
        <div className="absolute bottom-[-200px] right-[-50px] rotate-[130deg] md:right-0 md:bottom-[-180px]">
          <EmptyBacteria />
        </div>
      </div>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {showNotification && (
          <SubscriptionNotification
            setShowNotification={setShowNotification}
            variants={showErrorNotification}
            showNotification={showNotification}
          />
        )}
      </AnimatePresence>
    </>
  );
}
