import { ButtonHTMLAttributes } from 'react';

export const DefaultClassNames =
  'w-full sml:w-[374px] sm:w-[424px] md:w-[280px] h-[60px] bg-green rounded-full uppercase text-body font-semibold text-sm flex items-center justify-center';

export const SmallSizeButtonClassName =
  'w-[180px] h-[50px] bg-green rounded-full uppercase text-body font-semibold text-sm flex items-center justify-center';

const DEFAULT_CLASSES =
  'block m-0 w-full items-center justify-center uppercase font-medium text-center px-6 py-4 rounded disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed';

const VARIANT_CLASSES = {
  primary: 'text-white bg-gray-900 hover:bg-gray-800 active:bg-gray-700',
  secondary: 'bg-white hover:bg-gray-50 active:bg-gray-100 border border-black',
};

export const BUTTON_PRIMARY_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES.primary}`;
export const BUTTON_SECONDARY_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES.secondary}`;

export default function Button(
  props: ButtonHTMLAttributes<HTMLButtonElement> & { href?: string }
) {
  if (props.href) {
    return (
      <a href={props.href} className={DefaultClassNames}>
        {props.children}
      </a>
    );
  }

  return (
    <button className={DefaultClassNames} {...props}>
      {props.children}
    </button>
  );
}

Button.DefaultClassNames = DefaultClassNames;
