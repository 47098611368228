import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faTrashXmark } from '@fortawesome/pro-light-svg-icons';

export type ThrashIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const TrashIcon: React.FC<ThrashIconProps> = (props) => (
  <FontAwesomeIcon icon={faTrashXmark} {...props} />
);
