import AnalyticService, { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
// Warning googleTagManager uses browser property which is deprecated in Next
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';
// Warning mixpanelPlugin uses browser property which is deprecated in Next
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mixpanelPlugin from '@analytics/mixpanel';
import { analyticsPluginConsole } from './analytics-console-plugin';
import { SnapiEvent } from '@snapi/types';

const isProd = process.env['NODE_ENV'] === 'production';
const isBrowser = typeof window !== 'undefined';

function getPlugins() {
  const plugins: Array<AnalyticsPlugin> =
    isProd && isBrowser ? [] : [analyticsPluginConsole()];

  const mixpanelToken =
    process.env['NEXT_PUBLIC_MIXPANEL_TOKEN'] ||
    process.env['NX_MIXPANEL_TOKEN'];
  if (isBrowser && isProd && mixpanelToken) {
    plugins.push(
      mixpanelPlugin({
        token: mixpanelToken,
        customScriptSrc: '/js/mxp/script.js',
        options: {
          api_host: '/api/mxp/',
          disable_cookie: true,
        },
      })
    );
  }

  const tagManagerId =
    process.env['NEXT_PUBLIC_GOOGLE_ANALYTICS_ID'] ||
    process.env['NX_GOOGLE_TAG_MANAGER_ID'];
  if (isBrowser && isProd && tagManagerId) {
    plugins.push(googleTagManager({ containerId: tagManagerId }));
  }
  return plugins;
}

type SnapiAnalytics = AnalyticsInstance & {
  trackSnapiEvent(event: SnapiEvent): void;
};

export const Analytics: SnapiAnalytics = AnalyticService({
  app: 'Snapi',
  plugins: getPlugins(),
  debug: isProd,
}) as SnapiAnalytics;

Analytics.trackSnapiEvent = (event: SnapiEvent) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Analytics.track(event.type, event['payload']);
};
