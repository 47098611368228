import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

export type PlusIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const PlusIcon: React.FC<PlusIconProps> = (props) => (
  <FontAwesomeIcon icon={faPlus} {...props} />
);
