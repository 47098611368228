import { useCart } from '@shopify/hydrogen';

import { CartIcon } from '@snapi/icons';

/**
 * A client component that specifies the icon to use if a cart contains merchandise
 */
export default function CartIconWithItems() {
  const { totalQuantity } = useCart();

  return (
    <>
      <CartIcon className="w-[18px] h-4 flex self-center" />
      <span className="sr-only">Cart, {totalQuantity} items</span>
    </>
  );
}
