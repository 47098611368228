/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as general from '../lib';

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "private.baby"
 */
export enum baby_constraint {
  user_pkey = 'user_pkey',
}

/**
 * select columns of table "private.baby"
 */
export enum baby_select_column {
  createdAt = 'createdAt',
  delivery = 'delivery',
  dob = 'dob',
  gender = 'gender',
  id = 'id',
  name = 'name',
  parent_id = 'parent_id',
  preterm = 'preterm',
  updatedAt = 'updatedAt',
}

/**
 * select "baby_aggregate_bool_exp_bool_and_arguments_columns" columns of table "private.baby"
 */
export enum baby_select_column_baby_aggregate_bool_exp_bool_and_arguments_columns {
  preterm = 'preterm',
}

/**
 * select "baby_aggregate_bool_exp_bool_or_arguments_columns" columns of table "private.baby"
 */
export enum baby_select_column_baby_aggregate_bool_exp_bool_or_arguments_columns {
  preterm = 'preterm',
}

/**
 * update columns of table "private.baby"
 */
export enum baby_update_column {
  createdAt = 'createdAt',
  delivery = 'delivery',
  dob = 'dob',
  gender = 'gender',
  id = 'id',
  name = 'name',
  parent_id = 'parent_id',
  preterm = 'preterm',
  updatedAt = 'updatedAt',
}

export enum BacteriaClassification {
  disruptive = 'disruptive',
  key = 'key',
  other = 'other',
}

/**
 * unique or primary key constraints on table "microbiome.bacteria_threshold"
 */
export enum microbiome_bacteria_threshold_constraint {
  bacteria_threshold_pkey = 'bacteria_threshold_pkey',
}

/**
 * update columns of table "microbiome.bacteria_threshold"
 */
export enum microbiome_bacteria_threshold_update_column {
  classification = 'classification',
  comparator = 'comparator',
  condition = 'condition',
  formula = 'formula',
  from = 'from',
  id = 'id',
  researchId = 'researchId',
  threshold = 'threshold',
  to = 'to',
}

export enum RecommendationCategory {
  food = 'food',
  // lifestyle = 'lifestyle',
  mother = 'mother',
  // supplements = 'supplements',
}

/**
 * unique or primary key constraints on table "microbiome.recommendation"
 */
export enum microbiome_recommendation_constraint {
  recommendation_pkey = 'recommendation_pkey',
}

export enum RecommendationReliability {
  high = 'high',
  medium = 'medium',
}

export enum RecommendationType {
  extended = 'extended',
  short = 'short',
}

/**
 * update columns of table "microbiome.recommendation"
 */
export enum microbiome_recommendation_update_column {
  category = 'category',
  created_at = 'created_at',
  description = 'description',
  id = 'id',
  name = 'name',
  products = 'products',
  recipes = 'recipes',
  references = 'references',
  reliability = 'reliability',
  servings = 'servings',
  type = 'type',
  updated_at = 'updated_at',
}

export enum ResearchCategory {
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  protective = 'protective',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

/**
 * unique or primary key constraints on table "microbiome.research_category_stats"
 */
export enum microbiome_research_category_stats_constraint {
  species_category_stats_pkey = 'species_category_stats_pkey',
}

/**
 * select columns of table "microbiome.research_category_stats"
 */
export enum microbiome_research_category_stats_select_column {
  avg = 'avg',
  category = 'category',
  createdAt = 'createdAt',
  deviation = 'deviation',
  lower = 'lower',
  max = 'max',
  median = 'median',
  min = 'min',
  trimester = 'trimester',
  upper = 'upper',
}

/**
 * update columns of table "microbiome.research_category_stats"
 */
export enum microbiome_research_category_stats_update_column {
  avg = 'avg',
  category = 'category',
  createdAt = 'createdAt',
  deviation = 'deviation',
  lower = 'lower',
  max = 'max',
  median = 'median',
  min = 'min',
  trimester = 'trimester',
  upper = 'upper',
}

/**
 * unique or primary key constraints on table "microbiome.research"
 */
export enum microbiome_research_constraint {
  research_pkey = 'research_pkey',
}

/**
 * unique or primary key constraints on table "microbiome.research_to_recommendation"
 */
export enum microbiome_research_to_recommendation_constraint {
  research_to_recommendation_pkey = 'research_to_recommendation_pkey',
}

/**
 * select columns of table "microbiome.research_to_recommendation"
 */
export enum microbiome_research_to_recommendation_select_column {
  recommendation_id = 'recommendation_id',
  research_id = 'research_id',
}

/**
 * update columns of table "microbiome.research_to_recommendation"
 */
export enum microbiome_research_to_recommendation_update_column {
  recommendation_id = 'recommendation_id',
  research_id = 'research_id',
}

/**
 * update columns of table "microbiome.research"
 */
export enum microbiome_research_update_column {
  ancestor = 'ancestor',
  association = 'association',
  category = 'category',
  condition = 'condition',
  created_at = 'created_at',
  description = 'description',
  id = 'id',
  name = 'name',
  nutrition = 'nutrition',
  recommendation = 'recommendation',
  references = 'references',
  taxonomy = 'taxonomy',
  updated_at = 'updated_at',
}

export enum Study {
  israel = 'israel',
  moesm = 'moesm',
  snapi = 'snapi',
}

/**
 * unique or primary key constraints on table "microbiome.study_report_to_bacteria"
 */
export enum microbiome_study_report_to_bacteria_constraint {
  study_report_to_bacteria_threshold_pkey = 'study_report_to_bacteria_threshold_pkey',
}

/**
 * update columns of table "microbiome.study_report_to_bacteria"
 */
export enum microbiome_study_report_to_bacteria_update_column {
  bacteria_threshold_id = 'bacteria_threshold_id',
  classified = 'classified',
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  percentage = 'percentage',
  protective = 'protective',
  study_report_id = 'study_report_id',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

/**
 * unique or primary key constraints on table "microbiome.study_report_to_recommendation"
 */
export enum microbiome_study_report_to_recommendation_constraint {
  study_report_to_recommendation_pkey = 'study_report_to_recommendation_pkey',
}

/**
 * update columns of table "microbiome.study_report_to_recommendation"
 */
export enum microbiome_study_report_to_recommendation_update_column {
  reason = 'reason',
  recommendation_id = 'recommendation_id',
  score = 'score',
  study_id = 'study_id',
}

/**
 * unique or primary key constraints on table "microbiome.study_report_to_research"
 */
export enum microbiome_study_report_to_research_constraint {
  study_report_result_pkey = 'study_report_result_pkey',
}

/**
 * update columns of table "microbiome.study_report_to_research"
 */
export enum microbiome_study_report_to_research_update_column {
  classified = 'classified',
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  percentage = 'percentage',
  protective = 'protective',
  research_id = 'research_id',
  study_report_id = 'study_report_id',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

export enum Taxonomy {
  class = 'class',
  family = 'family',
  genus = 'genus',
  kingdom = 'kingdom',
  order = 'order',
  phylum = 'phylum',
  species = 'species',
}

export enum BabyDeliveryMode {
  caesarean = 'caesarean',
  other = 'other',
  vaginal = 'vaginal',
}

export enum Gender {
  boy = 'boy',
  girl = 'girl',
  other = 'other',
}

/**
 * unique or primary key constraints on table "private.invitation"
 */
export enum private_invitation_constraint {
  parent_shared_report_pkey = 'parent_shared_report_pkey',
}

/**
 * select columns of table "private.invitation"
 */
export enum private_invitation_select_column {
  created_at = 'created_at',
  email = 'email',
  firstName = 'firstName',
  id = 'id',
  invitedParentId = 'invitedParentId',
  lastName = 'lastName',
  parentId = 'parentId',
  updated_at = 'updated_at',
}

/**
 * update columns of table "private.invitation"
 */
export enum private_invitation_update_column {
  created_at = 'created_at',
  email = 'email',
  firstName = 'firstName',
  id = 'id',
  invitedParentId = 'invitedParentId',
  lastName = 'lastName',
  parentId = 'parentId',
  updated_at = 'updated_at',
}

/**
 * unique or primary key constraints on table "private.microbiome_test"
 */
export enum private_microbiome_test_constraint {
  microbiome_test_pkey = 'microbiome_test_pkey',
}

/**
 * select columns of table "private.microbiome_test"
 */
export enum private_microbiome_test_select_column {
  ageInMonths = 'ageInMonths',
  baby_id = 'baby_id',
  condition = 'condition',
  createdAt = 'createdAt',
  diet = 'diet',
  disruptive = 'disruptive',
  diversity = 'diversity',
  id = 'id',
  neutral = 'neutral',
  other = 'other',
  parent_id = 'parent_id',
  protective = 'protective',
  questionnaire = 'questionnaire',
  reportedAt = 'reportedAt',
  results = 'results',
  stats = 'stats',
  status = 'status',
  trimester = 'trimester',
  unclassified = 'unclassified',
  unknown = 'unknown',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "private.microbiome_test_to_bacteria"
 */
export enum private_microbiome_test_to_bacteria_constraint {
  microbiome_test_to_bacteria_pkey = 'microbiome_test_to_bacteria_pkey',
}

/**
 * select columns of table "private.microbiome_test_to_bacteria"
 */
export enum private_microbiome_test_to_bacteria_select_column {
  bacteria_threshold_id = 'bacteria_threshold_id',
  classified = 'classified',
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  parent_id = 'parent_id',
  percentage = 'percentage',
  protective = 'protective',
  test_id = 'test_id',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

/**
 * update columns of table "private.microbiome_test_to_bacteria"
 */
export enum private_microbiome_test_to_bacteria_update_column {
  bacteria_threshold_id = 'bacteria_threshold_id',
  classified = 'classified',
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  parent_id = 'parent_id',
  percentage = 'percentage',
  protective = 'protective',
  test_id = 'test_id',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

/**
 * unique or primary key constraints on table "private.microbiome_test_to_recommendation"
 */
export enum private_microbiome_test_to_recommendation_constraint {
  microbiome_test_to_recommendation_pkey = 'microbiome_test_to_recommendation_pkey',
}

/**
 * select columns of table "private.microbiome_test_to_recommendation"
 */
export enum private_microbiome_test_to_recommendation_select_column {
  reason = 'reason',
  recommendation_id = 'recommendation_id',
  score = 'score',
  test_id = 'test_id',
}

/**
 * update columns of table "private.microbiome_test_to_recommendation"
 */
export enum private_microbiome_test_to_recommendation_update_column {
  reason = 'reason',
  recommendation_id = 'recommendation_id',
  score = 'score',
  test_id = 'test_id',
}

/**
 * unique or primary key constraints on table "private.microbiome_test_to_research"
 */
export enum private_microbiome_test_to_research_constraint {
  microbiome_test_to_research_pkey = 'microbiome_test_to_research_pkey',
}

/**
 * select columns of table "private.microbiome_test_to_research"
 */
export enum private_microbiome_test_to_research_select_column {
  classified = 'classified',
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  parent_id = 'parent_id',
  percentage = 'percentage',
  protective = 'protective',
  research_id = 'research_id',
  test_id = 'test_id',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

/**
 * update columns of table "private.microbiome_test_to_research"
 */
export enum private_microbiome_test_to_research_update_column {
  classified = 'classified',
  disruptive = 'disruptive',
  neutral = 'neutral',
  other = 'other',
  parent_id = 'parent_id',
  percentage = 'percentage',
  protective = 'protective',
  research_id = 'research_id',
  test_id = 'test_id',
  unclassified = 'unclassified',
  unknown = 'unknown',
}

/**
 * update columns of table "private.microbiome_test"
 */
export enum private_microbiome_test_update_column {
  ageInMonths = 'ageInMonths',
  baby_id = 'baby_id',
  condition = 'condition',
  createdAt = 'createdAt',
  diet = 'diet',
  disruptive = 'disruptive',
  diversity = 'diversity',
  id = 'id',
  neutral = 'neutral',
  other = 'other',
  parent_id = 'parent_id',
  protective = 'protective',
  questionnaire = 'questionnaire',
  reportedAt = 'reportedAt',
  results = 'results',
  stats = 'stats',
  status = 'status',
  trimester = 'trimester',
  unclassified = 'unclassified',
  unknown = 'unknown',
  updatedAt = 'updatedAt',
}

/**
 * unique or primary key constraints on table "private.parent"
 */
export enum private_parent_constraint {
  parent_email_key = 'parent_email_key',
  parent_pkey = 'parent_pkey',
}

/**
 * update columns of table "private.parent"
 */
export enum private_parent_update_column {
  avatar = 'avatar',
  createdAt = 'createdAt',
  email = 'email',
  firstName = 'firstName',
  id = 'id',
  lastName = 'lastName',
  name = 'name',
  shopifyId = 'shopifyId',
  updatedAt = 'updatedAt',
}

export enum TestStatus {
  analyzing = 'analyzing',
  pending = 'pending',
  ready = 'ready',
  received = 'received',
}

/**
 * The three-letter currency codes that represent the world currencies used in stores. These include standard ISO 4217 codes, legacy codes,
 * and non-standard codes.
 */
export enum shopifyCurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KID = 'KID',
  KMF = 'KMF',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  STN = 'STN',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VED = 'VED',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  XXX = 'XXX',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
}

/**
 * The possible product subscription states for a customer, as defined by the customer's subscription contracts.
 */
export enum shopifyCustomerProductSubscriberStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  NEVER_SUBSCRIBED = 'NEVER_SUBSCRIBED',
  PAUSED = 'PAUSED',
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

export interface baby_aggregate_bool_exp {
  bool_and?: baby_aggregate_bool_exp_bool_and | null;
  bool_or?: baby_aggregate_bool_exp_bool_or | null;
  count?: baby_aggregate_bool_exp_count | null;
}

export interface baby_aggregate_bool_exp_bool_and {
  arguments: baby_select_column_baby_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: baby_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface baby_aggregate_bool_exp_bool_or {
  arguments: baby_select_column_baby_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: baby_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface baby_aggregate_bool_exp_count {
  arguments?: baby_select_column[] | null;
  distinct?: boolean | null;
  filter?: baby_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "private.baby"
 */
export interface baby_arr_rel_insert_input {
  data: baby_insert_input[];
  on_conflict?: baby_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "private.baby". All fields are combined with a logical 'AND'.
 */
export interface baby_bool_exp {
  _and?: baby_bool_exp[] | null;
  _not?: baby_bool_exp | null;
  _or?: baby_bool_exp[] | null;
  createdAt?: timestamptz_comparison_exp | null;
  delivery?: BabyDeliveryMode_comparison_exp | null;
  dob?: date_comparison_exp | null;
  gender?: Gender_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  parent?: private_parent_bool_exp | null;
  parent_id?: uuid_comparison_exp | null;
  preterm?: Boolean_comparison_exp | null;
  tests?: private_microbiome_test_bool_exp | null;
  tests_aggregate?: private_microbiome_test_aggregate_bool_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.baby"
 */
export interface baby_insert_input {
  createdAt?: general.timestamptz | null;
  delivery?: BabyDeliveryMode | null;
  dob?: general.date | null;
  gender?: Gender | null;
  id?: general.uuid | null;
  name?: string | null;
  parent?: private_parent_obj_rel_insert_input | null;
  parent_id?: general.uuid | null;
  preterm?: boolean | null;
  tests?: private_microbiome_test_arr_rel_insert_input | null;
  updatedAt?: general.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "private.baby"
 */
export interface baby_obj_rel_insert_input {
  data: baby_insert_input;
  on_conflict?: baby_on_conflict | null;
}

/**
 * on_conflict condition type for table "private.baby"
 */
export interface baby_on_conflict {
  constraint: baby_constraint;
  update_columns: baby_update_column[];
  where?: baby_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: general.date | null;
  _gt?: general.date | null;
  _gte?: general.date | null;
  _in?: general.date[] | null;
  _is_null?: boolean | null;
  _lt?: general.date | null;
  _lte?: general.date | null;
  _neq?: general.date | null;
  _nin?: general.date[] | null;
}

/**
 * input type for inserting data into table "faq"
 */
export interface faq_insert_input {
  answer?: general.FaqAnswer | null;
  category?: string | null;
  created_at?: general.timestamptz | null;
  id?: string | null;
  landing?: boolean | null;
  question?: string | null;
  updated_at?: general.timestamptz | null;
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: general.jsonb | null;
  _contains?: general.jsonb | null;
  _eq?: general.jsonb | null;
  _gt?: general.jsonb | null;
  _gte?: general.jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: general.jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: general.jsonb | null;
  _lte?: general.jsonb | null;
  _neq?: general.jsonb | null;
  _nin?: general.jsonb[] | null;
}

/**
 * Boolean expression to compare columns of type "BacteriaClassification". All fields are combined with logical 'AND'.
 */
export interface BacteriaClassification_comparison_exp {
  _eq?: BacteriaClassification | null;
  _in?: BacteriaClassification[] | null;
  _is_null?: boolean | null;
  _neq?: BacteriaClassification | null;
  _nin?: BacteriaClassification[] | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.bacteria_threshold". All fields are combined with a logical 'AND'.
 */
export interface microbiome_bacteria_threshold_bool_exp {
  _and?: microbiome_bacteria_threshold_bool_exp[] | null;
  _not?: microbiome_bacteria_threshold_bool_exp | null;
  _or?: microbiome_bacteria_threshold_bool_exp[] | null;
  classification?: BacteriaClassification_comparison_exp | null;
  comparator?: String_comparison_exp | null;
  condition?: jsonb_comparison_exp | null;
  formula?: Boolean_comparison_exp | null;
  from?: Int_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  recommendations?: microbiome_research_to_recommendation_bool_exp | null;
  recommendations_aggregate?: microbiome_research_to_recommendation_aggregate_bool_exp | null;
  research?: microbiome_research_bool_exp | null;
  researchId?: uuid_comparison_exp | null;
  threshold?: Int_comparison_exp | null;
  to?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.bacteria_threshold"
 */
export interface microbiome_bacteria_threshold_insert_input {
  classification?: BacteriaClassification | null;
  comparator?: string | null;
  condition?: general.jsonb | null;
  formula?: boolean | null;
  from?: number | null;
  id?: general.uuid | null;
  recommendations?: microbiome_research_to_recommendation_arr_rel_insert_input | null;
  research?: microbiome_research_obj_rel_insert_input | null;
  researchId?: general.uuid | null;
  threshold?: number | null;
  to?: number | null;
}

/**
 * input type for inserting object relation for remote table "microbiome.bacteria_threshold"
 */
export interface microbiome_bacteria_threshold_obj_rel_insert_input {
  data: microbiome_bacteria_threshold_insert_input;
  on_conflict?: microbiome_bacteria_threshold_on_conflict | null;
}

/**
 * on_conflict condition type for table "microbiome.bacteria_threshold"
 */
export interface microbiome_bacteria_threshold_on_conflict {
  constraint: microbiome_bacteria_threshold_constraint;
  update_columns: microbiome_bacteria_threshold_update_column[];
  where?: microbiome_bacteria_threshold_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.diversity_stats". All fields are combined with a logical 'AND'.
 */
export interface microbiome_diversity_stats_bool_exp {
  _and?: microbiome_diversity_stats_bool_exp[] | null;
  _not?: microbiome_diversity_stats_bool_exp | null;
  _or?: microbiome_diversity_stats_bool_exp[] | null;
  avg?: numeric_comparison_exp | null;
  deviation?: numeric_comparison_exp | null;
  lower?: numeric_comparison_exp | null;
  max?: numeric_comparison_exp | null;
  median?: numeric_comparison_exp | null;
  min?: numeric_comparison_exp | null;
  taxonomy?: String_comparison_exp | null;
  trimester?: Int_comparison_exp | null;
  upper?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.diversity_stats"
 */
export interface microbiome_diversity_stats_insert_input {
  avg?: general.numeric | null;
  deviation?: general.numeric | null;
  lower?: general.numeric | null;
  max?: general.numeric | null;
  median?: general.numeric | null;
  min?: general.numeric | null;
  taxonomy?: string | null;
  trimester?: number | null;
  upper?: general.numeric | null;
}

/**
 * input type for inserting object relation for remote table "microbiome.diversity_stats"
 */
export interface microbiome_diversity_stats_obj_rel_insert_input {
  data: microbiome_diversity_stats_insert_input;
}

/**
 * Boolean expression to filter rows from the table "microbiome.recommendation". All fields are combined with a logical 'AND'.
 */
export interface microbiome_recommendation_bool_exp {
  _and?: microbiome_recommendation_bool_exp[] | null;
  _not?: microbiome_recommendation_bool_exp | null;
  _or?: microbiome_recommendation_bool_exp[] | null;
  category?: RecommendationCategory_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  products?: jsonb_comparison_exp | null;
  recipes?: jsonb_comparison_exp | null;
  references?: jsonb_comparison_exp | null;
  reliability?: RecommendationReliability_comparison_exp | null;
  servings?: jsonb_comparison_exp | null;
  type?: RecommendationType_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "RecommendationCategory". All fields are combined with logical 'AND'.
 */
export interface RecommendationCategory_comparison_exp {
  _eq?: RecommendationCategory | null;
  _in?: RecommendationCategory[] | null;
  _is_null?: boolean | null;
  _neq?: RecommendationCategory | null;
  _nin?: RecommendationCategory[] | null;
}

/**
 * input type for inserting data into table "microbiome.recommendation"
 */
export interface microbiome_recommendation_insert_input {
  category?: RecommendationCategory | null;
  created_at?: general.timestamptz | null;
  description?: string | null;
  id?: general.uuid | null;
  name?: string | null;
  products?: general.jsonb | null;
  recipes?: general.jsonb | null;
  references?: general.jsonb | null;
  reliability?: RecommendationReliability | null;
  servings?: general.jsonb | null;
  type?: RecommendationType | null;
  updated_at?: general.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "microbiome.recommendation"
 */
export interface microbiome_recommendation_obj_rel_insert_input {
  data: microbiome_recommendation_insert_input;
  on_conflict?: microbiome_recommendation_on_conflict | null;
}

/**
 * on_conflict condition type for table "microbiome.recommendation"
 */
export interface microbiome_recommendation_on_conflict {
  constraint: microbiome_recommendation_constraint;
  update_columns: microbiome_recommendation_update_column[];
  where?: microbiome_recommendation_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "RecommendationReliability". All fields are combined with logical 'AND'.
 */
export interface RecommendationReliability_comparison_exp {
  _eq?: RecommendationReliability | null;
  _in?: RecommendationReliability[] | null;
  _is_null?: boolean | null;
  _neq?: RecommendationReliability | null;
  _nin?: RecommendationReliability[] | null;
}

/**
 * Boolean expression to compare columns of type "RecommendationType". All fields are combined with logical 'AND'.
 */
export interface RecommendationType_comparison_exp {
  _eq?: RecommendationType | null;
  _in?: RecommendationType[] | null;
  _is_null?: boolean | null;
  _neq?: RecommendationType | null;
  _nin?: RecommendationType[] | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.research". All fields are combined with a logical 'AND'.
 */
export interface microbiome_research_bool_exp {
  _and?: microbiome_research_bool_exp[] | null;
  _not?: microbiome_research_bool_exp | null;
  _or?: microbiome_research_bool_exp[] | null;
  ancestor?: uuid_comparison_exp | null;
  association?: jsonb_comparison_exp | null;
  category?: ResearchCategory_comparison_exp | null;
  condition?: jsonb_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  description?: jsonb_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  nutrition?: jsonb_comparison_exp | null;
  recommendation?: jsonb_comparison_exp | null;
  recommendations?: microbiome_research_to_recommendation_bool_exp | null;
  recommendations_aggregate?: microbiome_research_to_recommendation_aggregate_bool_exp | null;
  references?: jsonb_comparison_exp | null;
  taxonomy?: Taxonomy_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "ResearchCategory". All fields are combined with logical 'AND'.
 */
export interface ResearchCategory_comparison_exp {
  _eq?: ResearchCategory | null;
  _in?: ResearchCategory[] | null;
  _is_null?: boolean | null;
  _neq?: ResearchCategory | null;
  _nin?: ResearchCategory[] | null;
}

export interface microbiome_research_category_stats_aggregate_bool_exp {
  count?: microbiome_research_category_stats_aggregate_bool_exp_count | null;
}

export interface microbiome_research_category_stats_aggregate_bool_exp_count {
  arguments?: microbiome_research_category_stats_select_column[] | null;
  distinct?: boolean | null;
  filter?: microbiome_research_category_stats_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "microbiome.research_category_stats"
 */
export interface microbiome_research_category_stats_arr_rel_insert_input {
  data: microbiome_research_category_stats_insert_input[];
  on_conflict?: microbiome_research_category_stats_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.research_category_stats". All fields are combined with a logical 'AND'.
 */
export interface microbiome_research_category_stats_bool_exp {
  _and?: microbiome_research_category_stats_bool_exp[] | null;
  _not?: microbiome_research_category_stats_bool_exp | null;
  _or?: microbiome_research_category_stats_bool_exp[] | null;
  avg?: numeric_comparison_exp | null;
  category?: ResearchCategory_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  deviation?: numeric_comparison_exp | null;
  lower?: numeric_comparison_exp | null;
  max?: numeric_comparison_exp | null;
  median?: numeric_comparison_exp | null;
  min?: numeric_comparison_exp | null;
  trimester?: Int_comparison_exp | null;
  upper?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.research_category_stats"
 */
export interface microbiome_research_category_stats_insert_input {
  avg?: general.numeric | null;
  category?: ResearchCategory | null;
  createdAt?: general.timestamptz | null;
  deviation?: general.numeric | null;
  lower?: general.numeric | null;
  max?: general.numeric | null;
  median?: general.numeric | null;
  min?: general.numeric | null;
  trimester?: number | null;
  upper?: general.numeric | null;
}

/**
 * on_conflict condition type for table "microbiome.research_category_stats"
 */
export interface microbiome_research_category_stats_on_conflict {
  constraint: microbiome_research_category_stats_constraint;
  update_columns: microbiome_research_category_stats_update_column[];
  where?: microbiome_research_category_stats_bool_exp | null;
}

/**
 * input type for inserting data into table "microbiome.research"
 */
export interface microbiome_research_insert_input {
  ancestor?: general.uuid | null;
  association?: general.jsonb | null;
  category?: ResearchCategory | null;
  condition?: general.jsonb | null;
  created_at?: general.timestamptz | null;
  description?: general.jsonb | null;
  id?: general.uuid | null;
  name?: string | null;
  nutrition?: general.jsonb | null;
  recommendation?: general.jsonb | null;
  recommendations?: microbiome_research_to_recommendation_arr_rel_insert_input | null;
  references?: general.jsonb | null;
  taxonomy?: Taxonomy | null;
  updated_at?: general.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "microbiome.research"
 */
export interface microbiome_research_obj_rel_insert_input {
  data: microbiome_research_insert_input;
  on_conflict?: microbiome_research_on_conflict | null;
}

/**
 * on_conflict condition type for table "microbiome.research"
 */
export interface microbiome_research_on_conflict {
  constraint: microbiome_research_constraint;
  update_columns: microbiome_research_update_column[];
  where?: microbiome_research_bool_exp | null;
}

export interface microbiome_research_to_recommendation_aggregate_bool_exp {
  count?: microbiome_research_to_recommendation_aggregate_bool_exp_count | null;
}

export interface microbiome_research_to_recommendation_aggregate_bool_exp_count {
  arguments?: microbiome_research_to_recommendation_select_column[] | null;
  distinct?: boolean | null;
  filter?: microbiome_research_to_recommendation_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "microbiome.research_to_recommendation"
 */
export interface microbiome_research_to_recommendation_arr_rel_insert_input {
  data: microbiome_research_to_recommendation_insert_input[];
  on_conflict?: microbiome_research_to_recommendation_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.research_to_recommendation". All fields are combined with a logical 'AND'.
 */
export interface microbiome_research_to_recommendation_bool_exp {
  _and?: microbiome_research_to_recommendation_bool_exp[] | null;
  _not?: microbiome_research_to_recommendation_bool_exp | null;
  _or?: microbiome_research_to_recommendation_bool_exp[] | null;
  recommendation?: microbiome_recommendation_bool_exp | null;
  recommendation_id?: uuid_comparison_exp | null;
  research?: microbiome_research_bool_exp | null;
  research_id?: uuid_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.research_to_recommendation"
 */
export interface microbiome_research_to_recommendation_insert_input {
  recommendation?: microbiome_recommendation_obj_rel_insert_input | null;
  recommendation_id?: general.uuid | null;
  research?: microbiome_research_obj_rel_insert_input | null;
  research_id?: general.uuid | null;
}

/**
 * on_conflict condition type for table "microbiome.research_to_recommendation"
 */
export interface microbiome_research_to_recommendation_on_conflict {
  constraint: microbiome_research_to_recommendation_constraint;
  update_columns: microbiome_research_to_recommendation_update_column[];
  where?: microbiome_research_to_recommendation_bool_exp | null;
}

/**
 * input type for inserting data into table "microbiome.study_report"
 */
export interface microbiome_study_report_insert_input {
  ageInMonths?: number | null;
  bacteria?: microbiome_study_report_to_bacteria_arr_rel_insert_input | null;
  categoryStats?: microbiome_research_category_stats_arr_rel_insert_input | null;
  condition?: general.jsonb | null;
  createdAt?: general.timestamptz | null;
  diet?: general.jsonb | null;
  disruptive?: general.numeric | null;
  diversity?: general.jsonb | null;
  diversityRef?: microbiome_diversity_stats_obj_rel_insert_input | null;
  id?: string | null;
  neutral?: general.numeric | null;
  other?: general.numeric | null;
  protective?: general.numeric | null;
  questionnaire?: any | null;
  recommendations?: microbiome_study_report_to_recommendation_arr_rel_insert_input | null;
  reportedAt?: general.timestamptz | null;
  results?: general.jsonb | null;
  stats?: general.jsonb | null;
  study?: Study | null;
  summary?: microbiome_study_report_to_research_arr_rel_insert_input | null;
  trimester?: number | null;
  unclassified?: general.numeric | null;
  unknown?: general.numeric | null;
}

/**
 * input type for inserting array relation for remote table "microbiome.study_report_to_bacteria"
 */
export interface microbiome_study_report_to_bacteria_arr_rel_insert_input {
  data: microbiome_study_report_to_bacteria_insert_input[];
  on_conflict?: microbiome_study_report_to_bacteria_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.study_report_to_bacteria". All fields are combined with a logical 'AND'.
 */
export interface microbiome_study_report_to_bacteria_bool_exp {
  _and?: microbiome_study_report_to_bacteria_bool_exp[] | null;
  _not?: microbiome_study_report_to_bacteria_bool_exp | null;
  _or?: microbiome_study_report_to_bacteria_bool_exp[] | null;
  bacteria_threshold_id?: uuid_comparison_exp | null;
  classified?: numeric_comparison_exp | null;
  disruptive?: numeric_comparison_exp | null;
  neutral?: numeric_comparison_exp | null;
  other?: numeric_comparison_exp | null;
  percentage?: numeric_comparison_exp | null;
  protective?: numeric_comparison_exp | null;
  study_report_id?: String_comparison_exp | null;
  threshold?: microbiome_bacteria_threshold_bool_exp | null;
  unclassified?: numeric_comparison_exp | null;
  unknown?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.study_report_to_bacteria"
 */
export interface microbiome_study_report_to_bacteria_insert_input {
  bacteria_threshold_id?: general.uuid | null;
  classified?: general.numeric | null;
  disruptive?: general.numeric | null;
  neutral?: general.numeric | null;
  other?: general.numeric | null;
  percentage?: general.numeric | null;
  protective?: general.numeric | null;
  study_report_id?: string | null;
  threshold?: microbiome_bacteria_threshold_obj_rel_insert_input | null;
  unclassified?: general.numeric | null;
  unknown?: general.numeric | null;
}

/**
 * on_conflict condition type for table "microbiome.study_report_to_bacteria"
 */
export interface microbiome_study_report_to_bacteria_on_conflict {
  constraint: microbiome_study_report_to_bacteria_constraint;
  update_columns: microbiome_study_report_to_bacteria_update_column[];
  where?: microbiome_study_report_to_bacteria_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "microbiome.study_report_to_recommendation"
 */
export interface microbiome_study_report_to_recommendation_arr_rel_insert_input {
  data: microbiome_study_report_to_recommendation_insert_input[];
  on_conflict?: microbiome_study_report_to_recommendation_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.study_report_to_recommendation". All fields are combined with a logical 'AND'.
 */
export interface microbiome_study_report_to_recommendation_bool_exp {
  _and?: microbiome_study_report_to_recommendation_bool_exp[] | null;
  _not?: microbiome_study_report_to_recommendation_bool_exp | null;
  _or?: microbiome_study_report_to_recommendation_bool_exp[] | null;
  reason?: jsonb_comparison_exp | null;
  recommendation?: microbiome_recommendation_bool_exp | null;
  recommendation_id?: uuid_comparison_exp | null;
  score?: numeric_comparison_exp | null;
  study_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.study_report_to_recommendation"
 */
export interface microbiome_study_report_to_recommendation_insert_input {
  reason?: general.jsonb | null;
  recommendation?: microbiome_recommendation_obj_rel_insert_input | null;
  recommendation_id?: general.uuid | null;
  score?: general.numeric | null;
  study_id?: string | null;
}

/**
 * on_conflict condition type for table "microbiome.study_report_to_recommendation"
 */
export interface microbiome_study_report_to_recommendation_on_conflict {
  constraint: microbiome_study_report_to_recommendation_constraint;
  update_columns: microbiome_study_report_to_recommendation_update_column[];
  where?: microbiome_study_report_to_recommendation_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "microbiome.study_report_to_research"
 */
export interface microbiome_study_report_to_research_arr_rel_insert_input {
  data: microbiome_study_report_to_research_insert_input[];
  on_conflict?: microbiome_study_report_to_research_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "microbiome.study_report_to_research". All fields are combined with a logical 'AND'.
 */
export interface microbiome_study_report_to_research_bool_exp {
  _and?: microbiome_study_report_to_research_bool_exp[] | null;
  _not?: microbiome_study_report_to_research_bool_exp | null;
  _or?: microbiome_study_report_to_research_bool_exp[] | null;
  classified?: numeric_comparison_exp | null;
  disruptive?: numeric_comparison_exp | null;
  neutral?: numeric_comparison_exp | null;
  other?: numeric_comparison_exp | null;
  percentage?: numeric_comparison_exp | null;
  protective?: numeric_comparison_exp | null;
  research?: microbiome_research_bool_exp | null;
  research_id?: uuid_comparison_exp | null;
  study_report_id?: String_comparison_exp | null;
  unclassified?: numeric_comparison_exp | null;
  unknown?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "microbiome.study_report_to_research"
 */
export interface microbiome_study_report_to_research_insert_input {
  classified?: general.numeric | null;
  disruptive?: general.numeric | null;
  neutral?: general.numeric | null;
  other?: general.numeric | null;
  percentage?: general.numeric | null;
  protective?: general.numeric | null;
  research?: microbiome_research_obj_rel_insert_input | null;
  research_id?: general.uuid | null;
  study_report_id?: string | null;
  unclassified?: general.numeric | null;
  unknown?: general.numeric | null;
}

/**
 * on_conflict condition type for table "microbiome.study_report_to_research"
 */
export interface microbiome_study_report_to_research_on_conflict {
  constraint: microbiome_study_report_to_research_constraint;
  update_columns: microbiome_study_report_to_research_update_column[];
  where?: microbiome_study_report_to_research_bool_exp | null;
}

/**
 * input type for inserting data into table "microbiome.taxonomy_diversity_stats"
 */
export interface microbiome_taxonomy_diversity_stats_insert_input {
  avg?: general.numeric | null;
  deviation?: general.numeric | null;
  lower?: general.numeric | null;
  max?: general.numeric | null;
  median?: general.numeric | null;
  min?: general.numeric | null;
  taxonomy?: Taxonomy | null;
  trimester?: number | null;
  upper?: general.numeric | null;
}

/**
 * Boolean expression to compare columns of type "Taxonomy". All fields are combined with logical 'AND'.
 */
export interface Taxonomy_comparison_exp {
  _eq?: Taxonomy | null;
  _in?: Taxonomy[] | null;
  _is_null?: boolean | null;
  _neq?: Taxonomy | null;
  _nin?: Taxonomy[] | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: general.numeric | null;
  _gt?: general.numeric | null;
  _gte?: general.numeric | null;
  _in?: general.numeric[] | null;
  _is_null?: boolean | null;
  _lt?: general.numeric | null;
  _lte?: general.numeric | null;
  _neq?: general.numeric | null;
  _nin?: general.numeric[] | null;
}

/**
 * Boolean expression to compare columns of type "BabyDeliveryMode". All fields are combined with logical 'AND'.
 */
export interface BabyDeliveryMode_comparison_exp {
  _eq?: BabyDeliveryMode | null;
  _in?: BabyDeliveryMode[] | null;
  _is_null?: boolean | null;
  _neq?: BabyDeliveryMode | null;
  _nin?: BabyDeliveryMode[] | null;
}

/**
 * Boolean expression to compare columns of type "Gender". All fields are combined with logical 'AND'.
 */
export interface Gender_comparison_exp {
  _eq?: Gender | null;
  _in?: Gender[] | null;
  _is_null?: boolean | null;
  _neq?: Gender | null;
  _nin?: Gender[] | null;
}

export interface private_invitation_aggregate_bool_exp {
  count?: private_invitation_aggregate_bool_exp_count | null;
}

export interface private_invitation_aggregate_bool_exp_count {
  arguments?: private_invitation_select_column[] | null;
  distinct?: boolean | null;
  filter?: private_invitation_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "private.invitation"
 */
export interface private_invitation_arr_rel_insert_input {
  data: private_invitation_insert_input[];
  on_conflict?: private_invitation_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "private.invitation". All fields are combined with a logical 'AND'.
 */
export interface private_invitation_bool_exp {
  _and?: private_invitation_bool_exp[] | null;
  _not?: private_invitation_bool_exp | null;
  _or?: private_invitation_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  invitedParentId?: uuid_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  parentId?: uuid_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.invitation"
 */
export interface private_invitation_insert_input {
  created_at?: general.timestamptz | null;
  email?: string | null;
  firstName?: string | null;
  id?: general.uuid | null;
  invitedParentId?: general.uuid | null;
  lastName?: string | null;
  parentId?: general.uuid | null;
  updated_at?: general.timestamptz | null;
}

/**
 * on_conflict condition type for table "private.invitation"
 */
export interface private_invitation_on_conflict {
  constraint: private_invitation_constraint;
  update_columns: private_invitation_update_column[];
  where?: private_invitation_bool_exp | null;
}

export interface private_microbiome_test_aggregate_bool_exp {
  count?: private_microbiome_test_aggregate_bool_exp_count | null;
}

export interface private_microbiome_test_aggregate_bool_exp_count {
  arguments?: private_microbiome_test_select_column[] | null;
  distinct?: boolean | null;
  filter?: private_microbiome_test_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "private.microbiome_test"
 */
export interface private_microbiome_test_arr_rel_insert_input {
  data: private_microbiome_test_insert_input[];
  on_conflict?: private_microbiome_test_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "private.microbiome_test". All fields are combined with a logical 'AND'.
 */
export interface private_microbiome_test_bool_exp {
  _and?: private_microbiome_test_bool_exp[] | null;
  _not?: private_microbiome_test_bool_exp | null;
  _or?: private_microbiome_test_bool_exp[] | null;
  ageInMonths?: Int_comparison_exp | null;
  baby?: baby_bool_exp | null;
  baby_id?: uuid_comparison_exp | null;
  bacteria?: private_microbiome_test_to_bacteria_bool_exp | null;
  bacteria_aggregate?: private_microbiome_test_to_bacteria_aggregate_bool_exp | null;
  categoryStats?: microbiome_research_category_stats_bool_exp | null;
  categoryStats_aggregate?: microbiome_research_category_stats_aggregate_bool_exp | null;
  condition?: jsonb_comparison_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  diet?: jsonb_comparison_exp | null;
  disruptive?: numeric_comparison_exp | null;
  diversity?: jsonb_comparison_exp | null;
  diversityRef?: microbiome_diversity_stats_bool_exp | null;
  id?: String_comparison_exp | null;
  neutral?: numeric_comparison_exp | null;
  other?: numeric_comparison_exp | null;
  parent?: private_parent_bool_exp | null;
  parent_id?: uuid_comparison_exp | null;
  protective?: numeric_comparison_exp | null;
  questionnaire?: jsonb_comparison_exp | null;
  recommendations?: private_microbiome_test_to_recommendation_bool_exp | null;
  recommendations_aggregate?: private_microbiome_test_to_recommendation_aggregate_bool_exp | null;
  reportedAt?: timestamptz_comparison_exp | null;
  results?: jsonb_comparison_exp | null;
  stats?: jsonb_comparison_exp | null;
  status?: TestStatus_comparison_exp | null;
  summary?: private_microbiome_test_to_research_bool_exp | null;
  summary_aggregate?: private_microbiome_test_to_research_aggregate_bool_exp | null;
  trimester?: Int_comparison_exp | null;
  unclassified?: numeric_comparison_exp | null;
  unknown?: numeric_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.microbiome_test"
 */
export interface private_microbiome_test_insert_input {
  ageInMonths?: number | null;
  baby?: baby_obj_rel_insert_input | null;
  baby_id?: general.uuid | null;
  bacteria?: private_microbiome_test_to_bacteria_arr_rel_insert_input | null;
  categoryStats?: microbiome_research_category_stats_arr_rel_insert_input | null;
  condition?: general.jsonb | null;
  createdAt?: general.timestamptz | null;
  diet?: general.jsonb | null;
  disruptive?: general.numeric | null;
  diversity?: general.jsonb | null;
  diversityRef?: microbiome_diversity_stats_obj_rel_insert_input | null;
  id?: string | null;
  neutral?: general.numeric | null;
  other?: general.numeric | null;
  parent?: private_parent_obj_rel_insert_input | null;
  parent_id?: general.uuid | null;
  protective?: general.numeric | null;
  questionnaire?: any | null;
  recommendations?: private_microbiome_test_to_recommendation_arr_rel_insert_input | null;
  reportedAt?: general.timestamptz | null;
  results?: general.jsonb | null;
  stats?: general.jsonb | null;
  status?: TestStatus | null;
  summary?: private_microbiome_test_to_research_arr_rel_insert_input | null;
  trimester?: number | null;
  unclassified?: general.numeric | null;
  unknown?: general.numeric | null;
  updatedAt?: general.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "private.microbiome_test"
 */
export interface private_microbiome_test_obj_rel_insert_input {
  data: private_microbiome_test_insert_input;
  on_conflict?: private_microbiome_test_on_conflict | null;
}

/**
 * on_conflict condition type for table "private.microbiome_test"
 */
export interface private_microbiome_test_on_conflict {
  constraint: private_microbiome_test_constraint;
  update_columns: private_microbiome_test_update_column[];
  where?: private_microbiome_test_bool_exp | null;
}

export interface private_microbiome_test_to_bacteria_aggregate_bool_exp {
  count?: private_microbiome_test_to_bacteria_aggregate_bool_exp_count | null;
}

export interface private_microbiome_test_to_bacteria_aggregate_bool_exp_count {
  arguments?: private_microbiome_test_to_bacteria_select_column[] | null;
  distinct?: boolean | null;
  filter?: private_microbiome_test_to_bacteria_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "private.microbiome_test_to_bacteria"
 */
export interface private_microbiome_test_to_bacteria_arr_rel_insert_input {
  data: private_microbiome_test_to_bacteria_insert_input[];
  on_conflict?: private_microbiome_test_to_bacteria_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "private.microbiome_test_to_bacteria". All fields are combined with a logical 'AND'.
 */
export interface private_microbiome_test_to_bacteria_bool_exp {
  _and?: private_microbiome_test_to_bacteria_bool_exp[] | null;
  _not?: private_microbiome_test_to_bacteria_bool_exp | null;
  _or?: private_microbiome_test_to_bacteria_bool_exp[] | null;
  bacteria_threshold_id?: uuid_comparison_exp | null;
  classified?: numeric_comparison_exp | null;
  disruptive?: numeric_comparison_exp | null;
  neutral?: numeric_comparison_exp | null;
  other?: numeric_comparison_exp | null;
  parent?: private_parent_bool_exp | null;
  parent_id?: uuid_comparison_exp | null;
  percentage?: numeric_comparison_exp | null;
  protective?: numeric_comparison_exp | null;
  test_id?: String_comparison_exp | null;
  threshold?: microbiome_bacteria_threshold_bool_exp | null;
  unclassified?: numeric_comparison_exp | null;
  unknown?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.microbiome_test_to_bacteria"
 */
export interface private_microbiome_test_to_bacteria_insert_input {
  bacteria_threshold_id?: general.uuid | null;
  classified?: general.numeric | null;
  disruptive?: general.numeric | null;
  neutral?: general.numeric | null;
  other?: general.numeric | null;
  parent?: private_parent_obj_rel_insert_input | null;
  parent_id?: general.uuid | null;
  percentage?: general.numeric | null;
  protective?: general.numeric | null;
  test_id?: string | null;
  threshold?: microbiome_bacteria_threshold_obj_rel_insert_input | null;
  unclassified?: general.numeric | null;
  unknown?: general.numeric | null;
}

/**
 * on_conflict condition type for table "private.microbiome_test_to_bacteria"
 */
export interface private_microbiome_test_to_bacteria_on_conflict {
  constraint: private_microbiome_test_to_bacteria_constraint;
  update_columns: private_microbiome_test_to_bacteria_update_column[];
  where?: private_microbiome_test_to_bacteria_bool_exp | null;
}

export interface private_microbiome_test_to_recommendation_aggregate_bool_exp {
  count?: private_microbiome_test_to_recommendation_aggregate_bool_exp_count | null;
}

export interface private_microbiome_test_to_recommendation_aggregate_bool_exp_count {
  arguments?: private_microbiome_test_to_recommendation_select_column[] | null;
  distinct?: boolean | null;
  filter?: private_microbiome_test_to_recommendation_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "private.microbiome_test_to_recommendation"
 */
export interface private_microbiome_test_to_recommendation_arr_rel_insert_input {
  data: private_microbiome_test_to_recommendation_insert_input[];
  on_conflict?: private_microbiome_test_to_recommendation_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "private.microbiome_test_to_recommendation". All fields are combined with a logical 'AND'.
 */
export interface private_microbiome_test_to_recommendation_bool_exp {
  _and?: private_microbiome_test_to_recommendation_bool_exp[] | null;
  _not?: private_microbiome_test_to_recommendation_bool_exp | null;
  _or?: private_microbiome_test_to_recommendation_bool_exp[] | null;
  reason?: jsonb_comparison_exp | null;
  recommendation?: microbiome_recommendation_bool_exp | null;
  recommendation_id?: uuid_comparison_exp | null;
  score?: numeric_comparison_exp | null;
  test?: private_microbiome_test_bool_exp | null;
  test_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.microbiome_test_to_recommendation"
 */
export interface private_microbiome_test_to_recommendation_insert_input {
  reason?: general.jsonb | null;
  recommendation?: microbiome_recommendation_obj_rel_insert_input | null;
  recommendation_id?: general.uuid | null;
  score?: general.numeric | null;
  test?: private_microbiome_test_obj_rel_insert_input | null;
  test_id?: string | null;
}

/**
 * on_conflict condition type for table "private.microbiome_test_to_recommendation"
 */
export interface private_microbiome_test_to_recommendation_on_conflict {
  constraint: private_microbiome_test_to_recommendation_constraint;
  update_columns: private_microbiome_test_to_recommendation_update_column[];
  where?: private_microbiome_test_to_recommendation_bool_exp | null;
}

export interface private_microbiome_test_to_research_aggregate_bool_exp {
  count?: private_microbiome_test_to_research_aggregate_bool_exp_count | null;
}

export interface private_microbiome_test_to_research_aggregate_bool_exp_count {
  arguments?: private_microbiome_test_to_research_select_column[] | null;
  distinct?: boolean | null;
  filter?: private_microbiome_test_to_research_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * input type for inserting array relation for remote table "private.microbiome_test_to_research"
 */
export interface private_microbiome_test_to_research_arr_rel_insert_input {
  data: private_microbiome_test_to_research_insert_input[];
  on_conflict?: private_microbiome_test_to_research_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "private.microbiome_test_to_research". All fields are combined with a logical 'AND'.
 */
export interface private_microbiome_test_to_research_bool_exp {
  _and?: private_microbiome_test_to_research_bool_exp[] | null;
  _not?: private_microbiome_test_to_research_bool_exp | null;
  _or?: private_microbiome_test_to_research_bool_exp[] | null;
  classified?: numeric_comparison_exp | null;
  disruptive?: numeric_comparison_exp | null;
  neutral?: numeric_comparison_exp | null;
  other?: numeric_comparison_exp | null;
  parent_id?: uuid_comparison_exp | null;
  percentage?: numeric_comparison_exp | null;
  protective?: numeric_comparison_exp | null;
  research?: microbiome_research_bool_exp | null;
  research_id?: uuid_comparison_exp | null;
  test?: private_microbiome_test_bool_exp | null;
  test_id?: String_comparison_exp | null;
  unclassified?: numeric_comparison_exp | null;
  unknown?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.microbiome_test_to_research"
 */
export interface private_microbiome_test_to_research_insert_input {
  classified?: general.numeric | null;
  disruptive?: general.numeric | null;
  neutral?: general.numeric | null;
  other?: general.numeric | null;
  parent_id?: general.uuid | null;
  percentage?: general.numeric | null;
  protective?: general.numeric | null;
  research?: microbiome_research_obj_rel_insert_input | null;
  research_id?: general.uuid | null;
  test?: private_microbiome_test_obj_rel_insert_input | null;
  test_id?: string | null;
  unclassified?: general.numeric | null;
  unknown?: general.numeric | null;
}

/**
 * on_conflict condition type for table "private.microbiome_test_to_research"
 */
export interface private_microbiome_test_to_research_on_conflict {
  constraint: private_microbiome_test_to_research_constraint;
  update_columns: private_microbiome_test_to_research_update_column[];
  where?: private_microbiome_test_to_research_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "private.parent". All fields are combined with a logical 'AND'.
 */
export interface private_parent_bool_exp {
  _and?: private_parent_bool_exp[] | null;
  _not?: private_parent_bool_exp | null;
  _or?: private_parent_bool_exp[] | null;
  avatar?: String_comparison_exp | null;
  babies?: baby_bool_exp | null;
  babies_aggregate?: baby_aggregate_bool_exp | null;
  createdAt?: timestamptz_comparison_exp | null;
  email?: String_comparison_exp | null;
  firstName?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  lastName?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  shared?: private_invitation_bool_exp | null;
  shared_aggregate?: private_invitation_aggregate_bool_exp | null;
  shopifyId?: String_comparison_exp | null;
  updatedAt?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "private.parent"
 */
export interface private_parent_insert_input {
  avatar?: string | null;
  babies?: baby_arr_rel_insert_input | null;
  createdAt?: general.timestamptz | null;
  email?: string | null;
  firstName?: string | null;
  id?: general.uuid | null;
  lastName?: string | null;
  name?: string | null;
  shared?: private_invitation_arr_rel_insert_input | null;
  shopifyId?: string | null;
  updatedAt?: general.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "private.parent"
 */
export interface private_parent_obj_rel_insert_input {
  data: private_parent_insert_input;
  on_conflict?: private_parent_on_conflict | null;
}

/**
 * on_conflict condition type for table "private.parent"
 */
export interface private_parent_on_conflict {
  constraint: private_parent_constraint;
  update_columns: private_parent_update_column[];
  where?: private_parent_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "TestStatus". All fields are combined with logical 'AND'.
 */
export interface TestStatus_comparison_exp {
  _eq?: TestStatus | null;
  _in?: TestStatus[] | null;
  _is_null?: boolean | null;
  _neq?: TestStatus | null;
  _nin?: TestStatus[] | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: general.timestamptz | null;
  _gt?: general.timestamptz | null;
  _gte?: general.timestamptz | null;
  _in?: general.timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: general.timestamptz | null;
  _lte?: general.timestamptz | null;
  _neq?: general.timestamptz | null;
  _nin?: general.timestamptz[] | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: general.uuid | null;
  _gt?: general.uuid | null;
  _gte?: general.uuid | null;
  _in?: general.uuid[] | null;
  _is_null?: boolean | null;
  _lt?: general.uuid | null;
  _lte?: general.uuid | null;
  _neq?: general.uuid | null;
  _nin?: general.uuid[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
