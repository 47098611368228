import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faHome,
  IconLookup,
  faCog,
  faRightToBracket,
  faUser,
  faQuestion,
  faVideo
} from '@fortawesome/pro-light-svg-icons';

export type NavigationIconTypes =
  | 'home'
  | 'support'
  | 'instructions'
  | 'settings'
  | 'logout'
  | 'login'
  | 'video';

const NavigationToFAIcon: {
  [key in NavigationIconTypes]: IconLookup;
} = {
  home: faHome,
  support: faHeadset,
  settings: faCog,
  instructions: faQuestion,
  logout: faRightToBracket,
  login: faUser,
  video: faVideo
};

export type NavigationIconProps = Omit<FontAwesomeIconProps, 'icon'> & {
  nav: NavigationIconTypes;
};

export const NavigationIcon: React.FC<NavigationIconProps> = (props) => (
  <FontAwesomeIcon icon={NavigationToFAIcon[props.nav]} {...props} />
);
