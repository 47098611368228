import * as React from 'react';
import { AuthContext } from '@snapi/types';
import { Auth } from '@aws-amplify/auth';
import { Analytics } from '@snapi/analytics';
import { UseAuthContext } from '@snapi/ui-utils';
import { DefaultAuthService } from '@snapi/auth-service';
import * as Sentry from '@sentry/nextjs';

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const [state, setState] = React.useState<AuthContext['state']>({
    loaded: false,
    authorized: false,
    user: null,
  });

  const refresh = React.useCallback(async (): Promise<boolean> => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const { sub, avatar, name, email } = idToken.payload;

      setState({
        loaded: true,
        authorized: true,
        user: { id: sub, avatar, name },
      });

      Analytics.identify(idToken.payload['cognito:username'], {
        name,
        email,
        avatar,
      });

      return true;
    } catch (error) {
      setState({ loaded: true, authorized: false, user: null });
      Analytics.reset();
      return false;
    }
  }, []);

  React.useEffect(() => {
    refresh();
  }, []);

/* 	We can make it global for all pages
    But first need to clarify what pages are going to be public
		
	React.useEffect(() => {
		if (!state.loaded) {
			return;
		}

		if (!state.authorized and !props.pageIsPublic) {
			router.replace('auth/sign-in');
		}
	}, [state.authorized, state.loaded]);
	 */

  const answerCustomChallenge = async (
    username: string,
    answer: string
  ): Promise<boolean> => {
    try {
      await DefaultAuthService.answerCustomChallenge(username, answer);
      return refresh();
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  };

  const signOut = React.useCallback(async () => {
    setState({
      loaded: false,
      authorized: false,
      user: null,
    });
    await DefaultAuthService.signOut();
    window.location.replace('/');
  }, []);

  return React.createElement(
    UseAuthContext.Provider,
    {
      value: {
        state,
        auth: {
          ...DefaultAuthService,
          answerCustomChallenge,
          signOut,
        },
      },
    },
    props.children
  );
};
