import { motion, AnimatePresence } from 'framer-motion';
import SocialLinks from '../../social-links';
import { NavigationItemProps } from '../navigation';
import Button from '../../../base/button.client';
import { SmallSizeButtonClassName } from '../../../base/button.client';
import { NavigationIcon } from '@snapi/icons';
import { CloseIcon } from '@snapi/icons';
import Link from 'next/link';

const variants = {
  visible: { opacity: 1, display: 'block' },
  hidden: { opacity: 0, display: 'none' },
};

type NavMobileProps = {
  isOpened: boolean;
  closeMenuHandler: () => void;
  navs: NavigationItemProps[];
};

export const NavMobile: React.FC<NavMobileProps> = ({
  isOpened,
  closeMenuHandler,
  navs,
}) => {
  if (!isOpened) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div
        variants={variants}
        transition={{ duration: 0.35 }}
        initial="hidden"
        animate={isOpened ? 'visible' : 'hidden'}
        className="fixed top-0 bottom-0 left-0 right-0 z-20 flex flex-col justify-between w-full py-20 bg-dark overflow-auto lg:hidden"
      >
        <div className="container space-y-16 px-10 relative">
          <div
            onClick={closeMenuHandler}
            className="absolute top-[-10px] right-[48px]"
          >
            <CloseIcon
              width={24}
              height={24}
              className="cursor-pointer text-white"
            />
          </div>
          <div className="flex flex-col items-start space-y-4">
            <a
              className="flex text-white"
              href="https://my.getsnapi.com/auth/login"
            >
              <div className="flex items-center justify-center mr-2">
                <NavigationIcon nav="login" className="w-[14px] h-4" />
              </div>
              <span className="flex flex-1 mr-2 text-xl font-semibold text-white">
                My Account
              </span>
            </a>
            <a href="https://my.getsnapi.com" className="pb-5">
              <Button className={SmallSizeButtonClassName}>
                Activate test id
              </Button>
            </a>
            {navs.map((nav) => {
              if (nav.name !== 'Community')
                return (
                  <Link href={nav.href} key={nav.href}>
                    <button
                      onClick={closeMenuHandler}
                      className="text-xl font-semibold text-white flex"
                    >
                      {nav.name}
                    </button>
                  </Link>
                );
            })}
            <div className="pt-20">
              <SocialLinks color="text-blue-dark" />
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
