import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { Navigation } from './navigation';
import { useRouter } from 'next/router';

interface HeaderProps {
  navs: Array<{ href: string; name: string }>;
}

/**
 * A client component that specifies the content of the header on the website
 */
export default function Header({ navs }: HeaderProps) {
  const bannerRef = useRef();
  const bannerSeen = useInView(bannerRef, {});
  const bannerText = process.env.NEXT_PUBLIC_DISCOUNT_BANNER;
  const router = useRouter();
  const routesWithoutHeader = ['/start', '/gut_project', '/activate'];

  return (
    <>
      {!routesWithoutHeader.includes(router.route) ? (
        <header className="flex items-center justify-center h-15" role="banner">
          <div
            ref={bannerRef}
            className={
              bannerText
                ? 'flex flex-1 justify-center bg-gradient-to-r from-green to-blue'
                : 'hidden'
            }
          >
            <h4 className="text-white p-5 lg:p-[26px] text-center">
              {bannerText}
            </h4>
          </div>
          <motion.div
            className="fixed z-50 h-16 max-w-[1264px] sm:h-20 container"
            animate={bannerText && bannerSeen ? { top: 100 } : { top: 20 }}
          >
            <Navigation navs={navs} pathname="/" />
          </motion.div>
        </header>
      ) : null}
    </>
  );
}
