import { ResearchCategory } from '@snapi/types';

export const ResearchCategoryToBackgroundColor: {
  [key in ResearchCategory]: string;
} = {
  [ResearchCategory.neutral]: 'bg-blue',
  [ResearchCategory.protective]: 'bg-green',
  [ResearchCategory.disruptive]: 'bg-red',
  [ResearchCategory.unknown]: 'bg-yellow',
  [ResearchCategory.other]: 'bg-yellow',
  [ResearchCategory.unclassified]: 'bg-yellow',
};

export const ResearchCategoryToColor: { [key in ResearchCategory]: string } = {
  [ResearchCategory.neutral]: '#A5CEF5',
  [ResearchCategory.protective]: '#D3EDC8',
  [ResearchCategory.disruptive]: '#F58B8B',
  [ResearchCategory.unknown]: '#FFE096',
  [ResearchCategory.other]: '#FFE096',
  [ResearchCategory.unclassified]: '#FFE096',
};
