import * as React from 'react';
import { Baby, uuid } from '@snapi/types';

export interface MyAppState {
  selected: Baby | null;
  invited: boolean;
  userId: string | null;
}

export interface SetSelected {
  type: 'SET_SELECTED';
  payload: (Baby & { parent_id: uuid }) | null;
}

export interface SetUser {
  type: 'SET_USER_ID';
  payload: string | null;
}

export type MyAppActions = SetSelected | SetUser;

export interface IMyAppContext {
  state: { selected: Baby | null; invited: boolean; userId: string | null };
  setSelected(baby: Baby & { parent_id: uuid }): void;
}

export const myAppStateReducer = (state: MyAppState, action: MyAppActions) => {
  switch (action.type) {
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.payload,
        invited: action.payload?.parent_id !== state.userId,
      };
    case 'SET_USER_ID':
      return { ...state, userId: action.payload };
    default:
      return state;
  }
};

export const MyAppContext = React.createContext<IMyAppContext>({
  state: {
    selected: null,
    userId: null,
    invited: false,
  },
  setSelected: console.log,
});

export const useMyAppContext = () => React.useContext(MyAppContext);
