import Header from './header';
import Footer from './footer/footer';
import Cart from '../cart/cart';

interface LayoutProps {
  children: React.ReactNode;
}

export const Navs = [
  // { href: '/gut_project', name: 'Gut Project' },
  { href: '/science', name: 'Science' },
  {
    href: 'https://circle.snapihealth.com/join?invitation_token=f1fe8ad428cabbef88f7858857cdc15d88122665-22b3b427-36fb-4c72-b85c-3cc57158b62d',
    name: 'Community',
  },
  { href: '/faq', name: 'FAQ' },
];

/**
 * A server component that defines a structure and organization of a page that can be used in different parts of the Hydrogen app
 */
export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <div className="absolute top-0 left-0">
        <a
          href="#mainContent"
          className="p-4 focus:block sr-only focus:not-sr-only"
        >
          Skip to content
        </a>
      </div>
      <div className="min-h-screen max-w-screen text-dark font-sans bg-yellow-light">
        <Header navs={Navs} />
        <Cart />
        <main
          role="main"
          id="mainContent"
          className="relative flex flex-col overflow-hidden"
        >
          {children}
        </main>
        <Footer navs={Navs} />
      </div>
    </>
  );
}
