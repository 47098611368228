import * as React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

export type CheckIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const CheckIcon: React.FC<CheckIconProps> = (props) => (
  <FontAwesomeIcon icon={faCheck} {...props} />
);
