import * as React from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';

const topDash = {
  closed: { width: 16, y: 1, rotate: 0 },
  opened: { width: 20, y: 7, x: -2, rotate: 45 },
};

const middleDash = {
  closed: { width: 16, y: 5, opacity: 1 },
  opened: { width: 20, y: 5, opacity: 0 },
};

const bottomDash = {
  closed: { width: 16, y: 9, rotate: 0 },
  opened: { width: 20, y: 3, x: -2, rotate: -45 },
};

export type MenuIconProps = {
  open: boolean;
  onClick: () => void;
};

export const MenuIcon: React.FC<MenuIconProps> = ({ open, onClick }) => (
  <span
    role="menu"
    aria-label="Hamburger"
    className=" w-4 h-4 cursor-pointer"
    onClick={onClick}
  >
    <motion.div
      variants={topDash}
      initial="closed"
      animate={open ? 'opened' : 'closed'}
      transition={{ type: 'spring', duration: 0.4, bounce: 0.2 }}
      className={clsx('h-[2px]', open ? 'bg-white' : 'bg-body')}
    />
    <motion.div
      variants={middleDash}
      initial="closed"
      animate={open ? 'opened' : 'closed'}
      transition={{ type: 'spring', duration: 0.1, bounce: 0.2 }}
      className={clsx('h-[2px]', open ? 'bg-white' : 'bg-body')}
    />
    <motion.div
      variants={bottomDash}
      initial="closed"
      animate={open ? 'opened' : 'closed'}
      transition={{ type: 'spring', duration: 0.4, bounce: 0.2 }}
      className={clsx('h-[2px]', open ? 'bg-white' : 'bg-body')}
    />
  </span>
);
