import { CountryCode } from '@shopify/hydrogen/dist/esnext/storefront-api-types';
import LocalCartProvider from './local-cart-provider';

interface ServerCartProviderProps {
  countryCode?: CountryCode;
  children: React.ReactNode;
}

export default function ServerCartProvider({
  countryCode,
  children,
}: ServerCartProviderProps) {
  return (
    <LocalCartProvider countryCode={countryCode}>{children}</LocalCartProvider>
  );
}
