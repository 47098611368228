import {
  RegisterForm,
  LogInForm,
  AuthService,
  LogInRequest,
} from '@snapi/types';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { GraphQlClient } from '@snapi/query';
import { nanoid } from 'nanoid';
import './auth-listener';

Auth.configure({
  region: process.env['NEXT_PUBLIC_AUTH_REGION'],
  identityPoolId: process.env['NEXT_PUBLIC_AUTH_IDENTITY_POOL_ID'],
  userPoolId: process.env['NEXT_PUBLIC_AUTH_USER_POOL_ID'],
  userPoolWebClientId: process.env['NEXT_PUBLIC_AUTH_USER_POOL_WEB_CLIENT_ID'],
  oauth: {
    domain: process.env['NEXT_PUBLIC_AUTH_AWS_COGNITO_DOMAIN'],
    scope: ['email', 'profile', 'openid'],
    responseType: 'token',
    redirectSignIn: process.env['NEXT_PUBLIC_AUTH_AWS_REDIRECT_SIGN_IN'],
    redirectSignOut: process.env['NEXT_PUBLIC_AUTH_AWS_REDIRECT_SIGN_OUT'],
  },
});

const logIn = async ({ email }: LogInForm): Promise<boolean> => {
  const logInRequest: LogInRequest = { email, origin: window.origin };

  const postResponse = await fetch('https://api.snapihealth.com/auth/sign-in', {
    method: 'POST',
    body: JSON.stringify(logInRequest),
  });

  const body = await postResponse.json();

  if (body.link) {
    window.location.replace(body.link);
  }

  return postResponse.ok;
};

const register = async ({ email, name }: RegisterForm): Promise<boolean> => {
  try {
    await Auth.signUp({
      username: nanoid(),
      password: nanoid(64),
      attributes: { email, name },
    });
  } catch (e) {
    // skip if user already exists
  }

  const loggedIn = await logIn({ email });
  return loggedIn === true;
};

const federatedSignInWithGoogle = () =>
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });

const federatedSignInWithFacebook = () =>
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook,
  });

const federatedSignInWithApple = () =>
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Apple,
  });

const answerCustomChallenge = async (
  username: string,
  answer: string
): Promise<boolean> => {
  const cognitoUser = await Auth.signIn(username);

  if (!cognitoUser) {
    throw new Error('no cognito user');
  }

  const user = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
  if (!user) {
    throw new Error('no user after sendCustomChallenge');
  }

  if (!user?.attributes?.['custom:authChallenge']) {
    throw new Error('Custom challenge failed');
  }

  return user;
};

const signOut = async () => {
  await Auth.signOut();
  GraphQlClient.unauthorize();
};

export const DefaultAuthService: AuthService = {
  answerCustomChallenge,
  federatedSignInWithFacebook,
  federatedSignInWithGoogle,
  federatedSignInWithApple,
  logIn,
  register,
  signOut,
};
