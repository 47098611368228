import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

export type CloseIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const CloseIcon: React.FC<CloseIconProps> = (props) => (
  <FontAwesomeIcon icon={faTimes} {...props} />
);
