import { HubCapsule, Hub, Logger } from '@aws-amplify/core';
import { DefaultAuthService } from './default-auth-service';

const logger = new Logger('My-Logger');

const listener = (data: HubCapsule) => {
  switch (data.payload.event) {
    case 'signIn':
      logger.info('user signed in');
      break;
    case 'signUp':
      logger.info('user signed up');
      break;
    case 'signOut':
      logger.info('user signed out');
      break;
    case 'signIn_failure': {
      const error = data.payload.data?.message;
      if (error) {
        if (/already.found.an.entry.for.username.google/gi.test(error)) {
          DefaultAuthService.federatedSignInWithGoogle();
        } else if (
          /already.found.an.entry.for.username.facebook/gi.test(error)
        ) {
          DefaultAuthService.federatedSignInWithFacebook();
        } else if (
          /already.found.an.entry.for.username.signinwithapple/gi.test(error)
        ) {
          DefaultAuthService.federatedSignInWithApple();
        }
      }
      logger.error('user sign in failed');
      break;
    }
    case 'tokenRefresh':
      logger.info('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      logger.error('token refresh failed');
      break;
    case 'configured':
      logger.info('the Auth module is configured');
  }
};

Hub.listen('auth', listener);
