import { CheckIcon } from '@snapi/icons';
import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import { FieldError, RefCallBack } from 'react-hook-form';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: React.ReactNode;
  error?: FieldError;
  horizontal?: boolean;
  valid?: boolean;
};

export const Input = forwardRef<RefCallBack, InputProps>(
  (
    { error, label, valid, horizontal, className, ...rest }: InputProps,
    ref
  ) => {
    return (
      <div className={clsx('flex flex-col', horizontal && 'sm:items-center')}>
        <div
          className={clsx(
            'flex flex-col relative',
            horizontal && 'sm:flex-row'
          )}
        >
          {label && (
            <>
              <label
                className={clsx('flex flex-1 mb-2', horizontal && 'sm:hidden')}
              >
                <h5>{label}</h5>
              </label>
              {horizontal && (
                <label className="hidden sm:block absolute top-5 -left-[200px] right-[288px] text-right">
                  <h5>{label}</h5>
                </label>
              )}
            </>
          )}
          <div
            className={clsx(
              'relative flex flex-col flex-1',
              horizontal && 'sm:max-w-[280px] sm:w-[280px]',
              className
            )}
          >
            <input
              ref={ref as any}
              className={clsx(
                'relative text-body placeholder:text-gray-soft h-[60px] rounded-[10px] px-5 border outline-none focus:border-body disabled:bg-gray-soft',
                !valid && !error && 'border-gray-soft',
                valid && 'border-green',
                error && 'border-red',
                className
              )}
              {...rest}
            />
            {valid && (
              <CheckIcon className="absolute w-5 h-5 right-5 top-5 text-green" />
            )}
          </div>
        </div>
        {error ? (
          <div className="flex flex-row mt-1">
            <div className="flex flex-1 sm:hidden" />
            <div className="w-[280px] mt-2">
              <small className="text-red">
                {error.type === 'required' ? 'Required' : error.message}
              </small>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);
