import {
  useCart,
  CartCheckoutButton,
  CartLines,
  CartLineQuantityAdjustButton,
  CartLinePrice,
  CartLineQuantity,
  CartCost,
  useCartLine,
} from '@shopify/hydrogen';
import { format } from 'date-fns';
import { CartLineImage } from './cart-line-image';
import { useCartUI } from './cart-ui-provider';
import { motion, AnimatePresence } from 'framer-motion';
import { CloseIcon, MinusIcon, PlusIcon, TrashIcon } from '@snapi/icons';
import { usePlausible } from 'next-plausible';
import { PlausibleAnalyticEvents } from '../types';
import { Description, Subtitle } from '@snapi/luxury';

/**
 * A client component that contains the merchandise that a customer intends to purchase, and the estimated cost associated with the cart
 */

const PositionRight = {
  closed: { x: '100%', right: 0 },
  opened: { x: 0, right: 0 },
};

const SlideOverOverlay = {
  visible: { opacity: 0.75 },
  hidden: { opacity: 0 },
};

export default function Cart() {
  const { isCartOpen, closeCart } = useCartUI();
  const { totalQuantity } = useCart();

  return (
    <>
      <AnimatePresence>
        {isCartOpen && (
          <motion.div
            variants={SlideOverOverlay}
            transition={{ duration: 0.35 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={closeCart}
            className="fixed top-0 bottom-0 left-0 right-0 flex justify-center w-full bg-body z-30"
          />
        )}
      </AnimatePresence>
      <motion.div
        variants={PositionRight}
        initial="closed"
        animate={isCartOpen ? 'opened' : 'closed'}
        transition={{ duration: 0.35 }}
        className="
          fixed top-0 bottom-0 z-50 bg-white overflow-scroll
          flex flex-col
          min-w-[320px] sm:min-w-[400px] max-w-md"
      >
        <CartHeader />
        <div className="flex justify-center px-5 sm:px-10">
          <div className="bg-purple text-purple-dark py-4 rounded-lg w-full flex justify-center">
            <h5 className="w-[280px] sm:w-[304px] text-center">
              SPECIAL OFFER! FREE SHIPPING TODAY UNTIL THE END OF
              <span className="ml-1">{format(Date.now(), 'MMMM')}</span>
            </h5>
          </div>
        </div>
        {totalQuantity === 0 ? (
          <CartEmpty />
        ) : (
          <>
            <div className="flex flex-col justify-between h-full">
              <CartItems />
              <CartFooter />
            </div>
          </>
        )}
      </motion.div>
    </>
  );
}

function CartHeader() {
  const { closeCart } = useCartUI();
  return (
    <header className="pt-5 pb-4 px-5 sm:px-10 flex justify-between items-center text-body">
      <h4>Cart</h4>
      <button type="button" onClick={closeCart}>
        <CloseIcon className="h-5 w-5" />
        <span className="sr-only">Close cart</span>
      </button>
    </header>
  );
}

function CartItems() {
  return (
    <div className="px-5 sm:px-10" role="table" aria-label="Shopping cart">
      <div role="row" className="sr-only">
        <div role="columnheader">Product image</div>
        <div role="columnheader">Product details</div>
        <div role="columnheader">Price</div>
      </div>
      <CartLines>
        <LineInCart />
      </CartLines>
    </div>
  );
}

const SingleTest = () => {
  return (
    <div className="">
      <h5 className="mb-1 text-body">Snapi Baby Microbiome Test</h5>
      <div className="flex flex-col text-xs gap-y-1 text-body">
        <Description> • Bacteria insights & summary</Description>
        <Description> • Lab report & associations </Description>
        <Description> • Insight Recommendations </Description>
        <Description> • Overal Tracking</Description>
      </div>
    </div>
  );
};

const SubscriptionPlan = () => {
  return (
    <div className="">
      <h5 className="mb-1 text-body">Snapi Subscription plan </h5>
      <div className="flex flex-col text-xs gap-y-1 text-body">
        <Description> • 4 tests per year</Description>
        <Description> • Delivery every 3 Months </Description>
        <Description> • Bacteria insights & summary</Description>
        <Description> • Lab report & associations </Description>
        <Description> • Insight Recommendations </Description>
        <Description> • Overal Tracking</Description>
      </div>
    </div>
  );
};

function LineInCart() {
  const { attributes } = useCartLine();

  return (
    <div role="row" className="pt-7">
      <div className="flex">
        <div role="cell" className="flex-shrink-0 mr-5">
          <CartLineImage
            className="bg-white rounded-xl"
            alt=""
            width={84}
            height={84}
          />
        </div>
        <div
          role="cell"
          className="flex flex-col w-full justify-between items-start flex-grow-1"
        >
          {attributes[0]?.value === 'single test' ? (
            <SingleTest />
          ) : (
            <SubscriptionPlan />
          )}
        </div>
      </div>
      <div className="flex ml-[100px] items-center mt-5 justify-between">
        <CartItemQuantity />
        <div role="cell" className="flex flex-col justify-between items-end">
          <CartLineQuantityAdjustButton
            adjust="remove"
            aria-label="Remove from cart"
            className="disabled:pointer-events-all disabled:cursor-wait"
          >
            <div className="flex gap-2 text-body">
              <TrashIcon />
              <Subtitle>Delete</Subtitle>
            </div>
          </CartLineQuantityAdjustButton>
        </div>
        <h5 className="text-body">
          <CartLinePrice className="" />
        </h5>
      </div>
    </div>
  );
}

function CartItemQuantity() {
  return (
    <div className="flex rounded items-center overflow-auto text-body">
      <CartLineQuantityAdjustButton
        adjust="decrease"
        aria-label="Decrease quantity"
        className=" disabled:pointer-events-all disabled:cursor-wait flex items-center"
      >
        <MinusIcon className="h-2 w-4" />
      </CartLineQuantityAdjustButton>
      <CartLineQuantity
        as="div"
        className="text-gray-900 text-xs text-center"
      />
      <CartLineQuantityAdjustButton
        adjust="increase"
        aria-label="Increase quantity"
        className=" text-gray-400 disabled:pointer-events-all disabled:cursor-wait flex items-center"
      >
        <PlusIcon className="h-2 w-4" />
      </CartLineQuantityAdjustButton>
    </div>
  );
}

function CartFooter() {
  const plausible = usePlausible<PlausibleAnalyticEvents>();

  return (
    <footer className="flex flex-col px-5 sm:px-10 gap-3 mb-7">
      <div className="h-[1px] bg-body mt-5"></div>
      <div role="table" aria-label="Cost summary">
        <div role="row" className="flex justify-between">
          <h5 className="text-body" role="rowheader">
            Subtotal
          </h5>
          <CartCost
            amountType="subtotal"
            role="cell"
            className="text-right text-[14px] font-semibold text-body"
          />
        </div>
        <div className="">
          <Subtitle>
            Shipping and discount codes are added at checkout.
          </Subtitle>
        </div>
      </div>
      <div
        onClick={() =>
          plausible('Initiate Checkout', { props: { type: 'Checkout' } })
        }
      >
        <CartCheckoutButton
          id="shopify-checkout-button"
          className="flex justify-center items-center bg-green shadow-[1px_2px_0_0_#7DA46C] hover:bg-green-hover hover:shadow-none focus:bg-green-focus focus:shadow-none h-[42px] w-full rounded-md uppercase mt-1"
        >
          <h5 className="text-body">Proceed to checkout</h5>
        </CartCheckoutButton>
      </div>
    </footer>
  );
}

function CartEmpty() {
  return (
    <div className="p-7 flex flex-col">
      <div className="text-body flex justify-center">
        <Description>Your cart is empty</Description>
      </div>
    </div>
  );
}
