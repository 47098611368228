import { LogoIcon } from '@snapi/icons';
import FooterSubscriptionForm from './footer-subscription-form';
import SocialLinks from '../social-links';
import { Description, Subtitle } from '@snapi/luxury';
import Link from 'next/link';

interface FooterProps {
  navs: Array<{ href: string; name: string }>;
}

/**
 * A server component that specifies the content of the footer on the website
 */
export default function Footer({ navs }: FooterProps) {
  return (
    <footer className="bg-blue text-blue-dark" role="contentinfo" id='footer'>
      <div className="container flex flex-col pt-10">
        <div className="flex flex-col items-start sm:flex-wrap sm:flex-row lg:justify-between">
          <div className="flex flex-1 flex-col sm:flex-row sm:flex-wrap justify-between md:max-w-[944px] lg:max-w-full">
            <div className="flex flex-col gap-3">
              <LogoIcon width={80} height={34} />
              <div className="flex flex-col sm:w-[280px] md:w-[400px] lg:w-[300px] xl:w-[400px]">
                <Subtitle>
                  Snapi Health is for parents who want to learn more about their
                  baby&apos;s microbiome. Snapi&apos;s test kits are currently
                  exclusively intended to be used for wellness purposes. The
                  tests we offer are not intended to diagnose or treat disease,
                  or to substitute for a physician&apos;s consultation.
                </Subtitle>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="flex flex-1 py-10 sm:py-0 sm:items-start">
                <div className="flex flex-row justify-between flex-wrap sm:flex-nowrap sm:flex-col gap-3 w-full sm:justify-center lg:items-start lg:justify-center">
                  {navs.map(({ name, href }) => {
                    if (name !== 'My Account') {
                      return (
                        <div key={name} className="w-2/5 sm:w-full">
                          <Link href={href}>
                            <a>
                              <p className="font-semibold">{name}</p>
                            </a>
                          </Link>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:mt-10 lg:mt-0 lg:items-start">
              <FooterSubscriptionForm />
            </div>
          </div>
        </div>
        <div className="flex flex-1 border-b mt-6 container"></div>
      </div>
      <div className="container flex flex-col gap-4 items-center py-7 md:flex-row md:justify-between mb-[108px] sm:mb-0">
        <div className="flex">
          <Description>© Snapi Health 2023</Description>
        </div>
        <div className="flex justify-between space-x-4">
          <Link href="https://my.getsnapi.com/legal/privacy" passHref>
            <a>
              <Description>Privacy Policy</Description>
            </a>
          </Link>
          <Link href="https://my.getsnapi.com/legal/terms">
            <a>
              <Description>Terms&nbsp;&amp;&nbsp;Conditions</Description>
            </a>
          </Link>
        </div>
        <div className="flex justify-end">
          <SocialLinks color="text-blue-dark" />
        </div>
      </div>
    </footer>
  );
}
