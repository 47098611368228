import {
  faFacebookF,
  faInstagram,
  faMediumM,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

interface SocialLinksProps {
  color: string;
}

export default function SocialLinks({ color }: SocialLinksProps) {
  return (
    <div className={clsx([color], 'flex space-x-3')}>
      <a
        aria-label="Instagram"
        href="https://www.instagram.com/snapi_health/"
        className="flex flex-1 h-[30px] w-[30px] bg-white rounded-full items-center justify-center"
      >
        <FontAwesomeIcon icon={faInstagram} className="h-5 w-5" />
      </a>
      <a
        aria-label="TikTok"
        href="https://www.tiktok.com/@snapihealth"
        className="flex flex-1 h-[30px] w-[30px] bg-white rounded-full items-center justify-center"
      >
        <FontAwesomeIcon icon={faTiktok} className="h-5 w-5" />
      </a>
      <a
        aria-label="Facebook"
        href="https://www.facebook.com/SnapiHealth/"
        className="flex flex-1 h-[30px] w-[30px] bg-white rounded-full items-center justify-center"
      >
        <FontAwesomeIcon icon={faFacebookF} className="h-5 w-5" />
      </a>
      <a
        aria-label="Medium"
        href="https://snapihealth.medium.com/"
        className="flex flex-1 h-[30px] w-[30px] bg-white rounded-full items-center justify-center"
      >
        <FontAwesomeIcon icon={faMediumM} className="h-5 w-5" />
      </a>
    </div>
  );
}
