export interface UnAuthorizedUserState {
  authorized: false;
  user: null;
}

export interface AuthoizedUserState {
  authorized: true;
  user: { id: string; avatar: string; name: string };
}

export type AuthUserState = { loaded: boolean } & (
  | UnAuthorizedUserState
  | AuthoizedUserState
);

export interface AuthService {
  answerCustomChallenge(username: string, answer: string): Promise<boolean>;
  federatedSignInWithFacebook(): void;
  federatedSignInWithGoogle(): void;
  federatedSignInWithApple(): void;
  logIn(form: LogInForm): Promise<boolean>;
  register(form: RegisterForm): Promise<boolean>;
  signOut(): void;
}

export type AuthContext = {
  auth: AuthService;
  state: AuthUserState;
};

export const MockAuthContext: AuthContext = {
  state: {
    loaded: false,
    authorized: false,
    user: null,
  },
  auth: {
    logIn: () => Promise.resolve(false),
    register: () => Promise.resolve(false),
    federatedSignInWithGoogle: () => {
      console.log('federatedSignInWithGoogle');
    },
    federatedSignInWithFacebook: () => {
      console.log('federatedSignInWithFacebook');
    },
    federatedSignInWithApple: () => {
      console.log('federatedSignInWithApple');
    },
    answerCustomChallenge: () => Promise.resolve(true),
    signOut: () => Promise.resolve(),
  },
};

export interface RegisterForm {
  email: string;
  name: string;
}

export type LogInForm = Pick<RegisterForm, 'email'>;

export type LogInRequest = LogInForm & { origin: string };
