import React from 'react';
import { useCartLine } from '@shopify/hydrogen';
import Image, { ImageProps } from 'next/image';

interface CartLineImageProps {
  className: string;
  width: number;
  height: number;
  alt: string;
}

/**
 * The `CartLineImage` component renders an `Image` component for the cart line merchandise's image.
 * It must be a descendent of a `CartLineProvider` component.
 */
export function CartLineImage(props: CartLineImageProps) {
  const cartLine = useCartLine();
  if (!cartLine.merchandise.image) {
    return null;
  }

  const image = cartLine.merchandise.image;

  const imageProps: ImageProps = {
    src: image.url,
    width: image.width,
    height: image.height,
  };

  return React.createElement(Image, {
    ...imageProps,
    ...props,
  });
}
