import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';

const httpLink = new HttpLink({
  uri: 'https://hasura.snapihealth.com/v1/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  let token = undefined;
  try {
    const session = await Auth.currentSession();
    token = session.getIdToken().getJwtToken();
  } catch (e) {
    token = undefined;
  }

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }
  return { headers };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const getClient = () => client;

const unauthorize = () => {
  client.resetStore();
  return client;
};

export const GraphQlClient = {
  getClient,
  unauthorize,
};
