import * as React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/pro-light-svg-icons';

export type CartIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const CartIcon: React.FC<CartIconProps> = (props) => (
  <FontAwesomeIcon icon={faCartShopping} {...props} />
);
