import React, { useEffect } from 'react';
import { NavMobile } from './nav-mobile';
import { motion } from 'framer-motion';
import { LogoIcon, MenuIcon, NavigationIcon, LinkIcon } from '@snapi/icons';
import CartToggle from '../../cart/cart-toggle';
import { useCartUI } from '../../cart/cart-ui-provider';
import Link from 'next/link';
import Button from '../../base/button.client';
import { SmallSizeButtonClassName } from '../../base/button.client';
import { Description } from '@snapi/luxury';

export interface NavigationItemProps {
  name: string;
  href: string;
}

interface NavigationProps {
  pathname: string;
  navs: NavigationItemProps[];
  className?: string;
}

export const Navigation: React.FC<NavigationProps> = ({ navs }) => {
  const [isMenuOpened, setMenuOpened] = React.useState(false);
  const { isCartOpen } = useCartUI();

  useEffect(() => {
    if (isMenuOpened) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
    };
  }, [isMenuOpened]);

  return (
    <motion.nav
      animate={isCartOpen ? 'hidden' : 'shown'}
      transition={{
        duration: 0.35,
      }}
      variants={{
        hidden: { opacity: 0.25 },
        shown: { opacity: 1 },
      }}
      className="flex items-center h-[60px] sm:h-20 rounded-full shadow-sm bg-white"
    >
      <div className="flex flex-1 flex-col px-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center pl-0 sm:pl-6 gap-10 lg:pl-0">
            <Link href="/" passHref>
              <a
                className="block w-16 sm:w-20 pl-2"
                role="link"
                aria-label="Brand"
              >
                <LogoIcon />
              </a>
            </Link>
            <Link href="/science" passHref>
              <a
                className="hidden md:block"
                role="link"
                aria-label="SciencePage"
              >
                <p>Science</p>
              </a>
            </Link>
            {/* <Link href="https://www.snapihealth.com/gut_project" passHref>
              <a
                className="md:visible hidden md:flex items-center"
                role="link"
                aria-label="GutProjectPage"
              >
                <span className="mr-2">
                  <p>Gut Project</p>
                </span>
                <LinkIcon className="h-4" />
              </a>
            </Link> */}
          </div>
          <div className="flex items-center space-x-10">
            <CartToggle
              onClick={() => {
                if (isMenuOpened) setMenuOpened(false);
              }}
            />
            <a
              className="hidden sm:flex items-center"
              href="https://my.getsnapi.com/auth/login"
            >
              <NavigationIcon nav="login" className="h-4" />
              <span className="ml-2">
                <p>My Account</p>
              </span>
            </a>
            <a className="hidden lg:block" href="https://my.getsnapi.com">
              <Button className={SmallSizeButtonClassName}>
                Activate test id
              </Button>
            </a>
            <span className="relative z-30 flex flex-col justify-center w-6 h-6 lg:hidden">
              {!isMenuOpened && (
                <MenuIcon
                  open={isMenuOpened}
                  onClick={() => setMenuOpened(!isMenuOpened)}
                />
              )}
            </span>
          </div>
        </div>
      </div>
      <NavMobile
        isOpened={isMenuOpened}
        closeMenuHandler={() => setMenuOpened(false)}
        navs={navs}
      />
    </motion.nav>
  );
};
