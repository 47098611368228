import { useCallback } from 'react';
import { CartProvider as ShopifyCartProvider } from '@shopify/hydrogen';
import CartUIProvider, { useCartUI } from './cart-ui-provider';
import { CountryCode } from '@shopify/hydrogen/dist/esnext/storefront-api-types';

interface LocalCartProviderProps {
  countryCode?: CountryCode;
  numCartLines?: number;
  children: React.ReactNode;
}

/**
 * A client component that creates a cart object and provides callbacks that can be accessed by any descendent component using the `useCart` hook and related hooks
 */
export default function LocalCartProvider({
  children,
  countryCode,
  numCartLines,
}: LocalCartProviderProps) {
  return (
    <CartUIProvider>
      <Provider countryCode={countryCode} numCartLines={numCartLines}>
        {children}
      </Provider>
    </CartUIProvider>
  );
}

function Provider({
  children,
  countryCode,
  numCartLines,
}: LocalCartProviderProps) {
  const { openCart } = useCartUI();

  const open = useCallback(() => {
    openCart();
  }, [openCart]);

  return (
    <ShopifyCartProvider
      countryCode={countryCode}
      numCartLines={numCartLines}
      onLineAdd={open}
      onCreate={open}
    >
      {children}
    </ShopifyCartProvider>
  );
}
