import { useCartUI } from './cart-ui-provider';
import CartIconWithItems from './cart-icon-with-items';
import { useCart } from '@shopify/hydrogen';
import { Description } from '@snapi/luxury';

interface CartToggleProps {
  onClick: () => void;
}

/**
 * A client component that defines the behavior when a user toggles a cart
 */
export default function CartToggle({ onClick }: CartToggleProps) {
  const cartUI = useCartUI();
  const { totalQuantity } = useCart();
  if (cartUI == null) {
    throw new Error('CartToggle must be a descendent of a CartUIProvider');
  }

  const { isCartOpen, toggleCart } = cartUI;

  const handleClick = () => {
    toggleCart();
    onClick();
  };

  return (
    <button
      type="button"
      aria-expanded={isCartOpen}
      aria-controls="cart"
      onClick={handleClick}
      className="flex items-center"
    >
      <CartIconWithItems />
      <span className="ml-2">
        <p>Cart {totalQuantity > 0 ? `(${totalQuantity})` : null}</p>
      </span>
    </button>
  );
}
