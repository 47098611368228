import { motion, AnimatePresence } from 'framer-motion';
import { CloseIcon } from '@snapi/icons';
import clsx from 'clsx';

const Overlay = {
  visible: { opacity: 0.75 },
  hidden: { opacity: 0 },
};

const NotificationAnimation = {
  closed: { opacity: 0 },
  opened: { opacity: 1 },
};

interface SubscriptionNotificationProps {
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  variants: boolean;
  showNotification: boolean;
}

export default function SubscriptionNotification({
  setShowNotification,
  variants,
  showNotification,
}: SubscriptionNotificationProps) {
  const onClickCNotificationHandler = (e) => {
    if (e.target === e.currentTarget) {
      setShowNotification(false);
    }
  };

  const onClickCloseVectorHandler = (e) => {
    if (e.target) {
      setShowNotification(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          variants={Overlay}
          transition={{ duration: 0.35 }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={onClickCNotificationHandler}
          className="bg-body fixed top-0 bottom-0 left-0 right-0  flex justify-center w-full z-[60]"
        />
      </AnimatePresence>
      <motion.div
        variants={NotificationAnimation}
        initial="closed"
        animate={showNotification ? 'opened' : 'closed'}
        transition={{ duration: 0.35 }}
        className={
          variants
            ? 'fixed inset-y-0 my-auto inset-x-0 mx-auto bg-pink-error w-[290px] max-h-max py-10 flex flex-col items-center justify-center rounded-xl z-[70]'
            : 'fixed inset-y-0 my-auto inset-x-0 mx-auto bg-green w-[290px] max-h-max py-10 flex flex-col items-center justify-center rounded-xl z-[70]'
        }
      >
        <div
          onClick={onClickCloseVectorHandler}
          className="absolute top-[18px] right-[18px]"
        >
          <CloseIcon
            className={clsx(
              'absolute top-[18px] right-[18px]',
              variants
                ? 'cursor-pointer fill-white'
                : 'cursor-pointer fill-body'
            )}
          />
        </div>
        {variants ? (
          <h4 className="mb-3 text-white">Ooops...</h4>
        ) : (
          <h4 className="mb-3">Thank you!</h4>
        )}
        {variants ? (
          <p className="w-[156px] text-center text-white">
            Something went wrong. Please try again
          </p>
        ) : (
          <p className="w-[156px] text-center">
            We recieved your email. Check your inbox
          </p>
        )}
      </motion.div>
    </>
  );
}
