import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@snapi/form';
import { Button } from '@snapi/luxury';
import { useForm } from 'react-hook-form';

export interface MailSubscriptionRequest {
  name?: string;
  audienceId: string;
  email: string;
  tags: string[];
}

export type SubscriptionForm = {
  audienceId: string;
  email: string;
};

export default function FooterSubscriptionForm() {
  const {
    register,
    formState: { isSubmitting },
    reset,
    setError,
    handleSubmit,
  } = useForm<SubscriptionForm>({});

  const onSubmit = async (form: SubscriptionForm) => {
    const req = {
      ...form,
      audienceId: '6e3bdda2a9',
      tags: ['Footer'],
    };

    const res = await fetch('https://api.snapihealth.com/mail/subscription', {
      method: 'POST',
      body: JSON.stringify(req),
    });
    const responseKlavio = await fetch(
      'https://www.snapihealth.com/api/subscription',
      {
        method: 'post',
        body: JSON.stringify({ ...form, listId: 'UUf6vv' }),
      }
    );
    console.log(responseKlavio.status);

    if (!res.ok) {
      if (res.status === 400) {
        setError('email', { message: 'Looks invalid, please check.' });
      }
    } else {
      reset();
    }
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <h4 className="mb-3">Looking to receive updates?</h4>
      <div className="flex flex-col sm:flex-row sm:gap-1 lg:flex-col">
        <Input
          type="email"
          placeholder="email@example.com"
          {...register('email', { required: true })}
          className="w-full sml:w-[376px] sm:w-[280px]"
        />
        <div className="mt-2 sm:mt-0 flex justify-start">
          <button
            className="w-full sml:w-[376px] sm:w-[280px] h-[60px] rounded-[10px] font-semibold uppercase shadow-[1px_2px_0_0_#7DA46C] bg-green text-body"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <span>Subscribe</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
