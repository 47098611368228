import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/pro-solid-svg-icons';

export type MinusIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const MinusIcon: React.FC<MinusIconProps> = (props) => (
  <FontAwesomeIcon icon={faMinus} {...props} />
);
