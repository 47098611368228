import PlausibleProvider from 'next-plausible';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  ShopifyContext,
  LocalizationContext,
} from 'node_modules/@shopify/hydrogen/dist/esnext/foundation/ShopifyProvider/ShopifyProvider.client.js';
import {
  CountryCode,
  LanguageCode,
} from 'node_modules/@shopify/hydrogen/dist/esnext/storefront-api-types';
import ServerCartProvider from '../components/cart/server-cart-provider';
import GTM from '../components/google-tag-manager';
import Layout from '../components/layout/layout';
import 'core-js/proposals/promise-finally';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-notion-x/src/styles.css';
import '../styles/global.css';

import { ShopifyAnalyticsClient } from '../utils/shopify-analytics-client';
import { PopUp } from '../components/pop-up';
import CoverImg from '../components/landing/cover/images/hero.png';
import { AuthProvider } from '@snapi/auth-provider';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from '@snapi/query';

function CustomApp({ Component, pageProps }: AppProps) {
  GTM();
  const router = useRouter();
  const routesWithoutHeader = ['/start', '/gut_project', '/activate'];

  return (
    <>
      <Head>
        <title>Snapihealth</title>
        <meta property="og:image" content={CoverImg.src} />
        <meta property="og:image:width" content={`${CoverImg.width}`} />
        <meta property="og:image:height" content={`${CoverImg.height}`} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:alt" content="A Snapi Microbiome Test" />
      </Head>
      <main className="app">
        <AuthProvider>
          <ApolloProvider client={GraphQlClient.getClient()}>
            <PlausibleProvider domain="snapihealth.com">
              <LocalizationContext.Provider
                value={{
                  country: {
                    isoCode: CountryCode.Us,
                  },
                  language: {
                    isoCode: LanguageCode.En,
                  },
                  locale: `${LanguageCode.En}-${CountryCode.Us}`,
                }}
              >
                <ShopifyContext.Provider
                  value={{
                    defaultCountryCode: 'US',
                    defaultLanguageCode: 'EN',
                    storeDomain: 'snapihealth.myshopify.com',
                    storefrontToken: 'dd718cc4a3f9c00b6db6bc32990d5228',
                    storefrontApiVersion: '2022-07',
                    storefrontId: '',
                  }}
                >
                  <ShopifyAnalyticsClient cookieDomain="snapihealth.myshopify.com" />
                  <ServerCartProvider countryCode={CountryCode.Us}>
                    <Layout>
                      <Component {...pageProps} />
                      {!routesWithoutHeader.includes(router.route) ? (
                        <PopUp />
                      ) : null}
                    </Layout>
                  </ServerCartProvider>
                </ShopifyContext.Provider>
              </LocalizationContext.Provider>
            </PlausibleProvider>
          </ApolloProvider>
        </AuthProvider>
      </main>
    </>
  );
}

export default CustomApp;
